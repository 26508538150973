img.image_on.icons {
  height: 25px;
  width: 25px;
}

.Managequiz-container {
  padding: 20px;
  max-width: 90%;
  margin: auto;
  padding-top: 0px;
}

.managequiz_header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.managequiz_header h1 {
  font-size: 32px;
  font-weight: 600;
  color: #052949;
  margin-inline: auto;
}

.managequiz-data {
  min-height: 66vh;
}

.managequiztable {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.managequiztable th {
  border-top: 1.2px solid #a05919;
  border-bottom: 1.2px solid #a05919;
  padding-top: 10px;
  padding-left: 10px;
  vertical-align: middle;
  border-right: 1px solid #a05919;
  padding-bottom: 10px;
  font-size: 16px;
  z-index: -1;
  color: #a05919;
}
.iconlink td:hover {
  color: rgb(72, 167, 255);
}
.managequiztable .quiztitle {
  color: #052949;
  font-weight: 700;
  word-break: normal;
}

.managequiztable tr td {
  color: #052949;
  font-size: 14px;
}

.managequiztable th,
.managequiztable td {
  /* padding: 10px;  */
  padding-inline: 10px;
}

.managequiztable .icons {
  height: 20px;
  width: 20px;
  margin: 0 auto;
  margin-left: 11%;
  margin-bottom: 10px;
  margin-top: 10px;
}
.managequiztable .icons:hover {
  transform: scale(1.1);
}

.managequiztable .quiz_toolbox {
  width: 250px;
  height: auto;
  display: inline-flex;
  justify-content: space-around;
}

.managequiztable tr {
  border-bottom: 2px solid #a0591973;
  text-align: center;
  padding-left: 5px;
}

.league__champs {
  display: flex;
  justify-content: center;
  max-width: 300px;
  flex-direction: column;
  align-items: center;
}

.Delete_popup {
  display: block;
  text-align: left;
  width: 317%;
  float: right;
  position: relative;
  top: -44px;
  left: -235px;
  padding: 0px 9%;
  background: rgb(255, 255, 255);
}

.Delete_popup button {
  float: left;
  color: white;
  background-color: #54b8e9;
  width: 165px;
  margin-left: 0%;
  font-size: 17px;
  border: none;
  border-radius: 6px;
  margin-right: 21px;
  margin-bottom: 5px;
  height: 30px;
  white-space: nowrap;
}

#closed-option {
  opacity: 0.5;
  cursor: not-allowed;
}

#create_quiz_mq {
  color: #ffffff;
  font-weight: 600;
  background: #052949;
  border: 2px solid #052949;
  border-radius: 12px;
  height: 50px;
  padding: 9px 15px 10px 20px;
  width: 18rem;
  font-size: 22px;
}
/* Pagination */
.pagination {
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 0px;
}
.pagination button {
  display: flex;
  font-weight: 600;
  align-items: center;
  padding: 5.75px 22px;
  font-family: "Inter";
  font-size: 15px;
}
.custom-pagination {
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 0px;
}
.pagination button svg {
  filter: invert(20%) sepia(96%) saturate(2247%) hue-rotate(197deg)
    brightness(90%) contrast(107%);
}

.previouspage,
.nextpage {
  display: inline-block;
  margin-right: 6px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
}
.pagenumber {
  display: inline-block;
  padding: 4.5px;
  margin-right: 6px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
}

.manage_active {
  background: #0057b3;
  color: white;
}
.manage_active:hover {
  background: #0057b3 !important;
}

.previouspage,
.nextpage {
  display: inline-block;
  cursor: pointer;
  color: #0057b3;
}

.pagelink {
  text-decoration: none;
  margin: 0 5px;
  padding: 8px 12px;
  color: inherit;
  max-height: 36px;
}

.pagenumber:hover,
.pagelink:hover {
  text-decoration: none;
  background-color: #bcdcff6d;
}
.pagelink svg {
  font-size: 19px;
  padding: 5px 0px;
}
#integerSelect {
  border: 1.2px solid #052949;
  color: #052949;
  font-weight: 500;
  border-radius: 4px;
}

.break-me {
  display: inline-block;
  margin: 0 5px;
}
/* Quiz Title css */

/* select dropdown */

.customdropdown {
  position: relative;
  display: inline-block;
  min-width: 90%;
}
.detailcontainer {
  width: 588px;
  z-index: 2;
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.5);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dropheader {
  color: #0057b3;
  border: 1px solid #ccc;
  padding: 8px;
  max-height: 40px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  min-width: 140px;
  display: flex;
  text-align: left;
  text-transform: capitalize;
  transition: border 0.3s;
  border-radius: 10px;
  font-weight: 500;
  justify-content: space-between;
  align-items: center;
  padding-block: 0px;
}
.restoredowndetailcontainer {
  display: flex;
  justify-content: flex-start;
  margin: auto;
  height: 100%;
}
.Takequiz_container {
  width: 60%;
  height: 80vh;
  height: 100%;
  display: flex;
}
.takequiztittle {
  text-align: center;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
}

.dropdownoptions {
  position: absolute;
  border: 1px solid #ccc;
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 10;
  height: 153px;
  overflow-y: auto;
  background-color: white;
  width: 99%;
  border-radius: 10px 10px;
  height: auto;
}
.quizStartPageMain {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.dropdownoptions li {
  font-size: 16px;
  padding: 3px;
  padding-left: 10px;
  cursor: pointer;
  text-align: left;
  color: #0057b3;
}

.dropdownoptions li:hover {
  background-color: #f0f0f0;
}

.dropheader .closedicon::before {
  content: "\23F5";
  margin-left: 8px;
  font-size: 20px;
  position: relative;
  top: -2px;
}

.dropheader .openicon::before {
  content: "\25BC";
  margin-left: 8px;
  font-size: 12px;
  position: relative;
  top: -2px;
}
.detailtable {
  width: 96%;
  font-weight: bold;
  border-spacing: 20px;
  text-align: left;
}
.detailtable td {
  padding: 5px;
  width: 50%;
}

.dropheader.live {
  color: Green;
  border: 1px solid Green;
}
.detailtable td label {
  font-size: 16px;
  color: #052949;
}
.dropheader.draft {
  color: gray;
  border: 1px solid gray;
}
.detailtable input,
.detailtable select {
  width: 100%;
  resize: none;
  border: 1px solid rgba(5, 41, 73, 0.5);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: -5%;
}
.dropheader.paused {
  color: #bb9900;
  border: 1px solid #bb9900;
}
.takequizstartbtn {
  font-size: 20px;
  color: white;
  border-radius: 4px;
  border: none;
  padding: 2% 0;
  background-color: #4598e1;
  border-radius: 10px;
  width: 88%;
  margin-top: 20px;
}
.dropheader.scheduled {
  color: orange;
  border: 1px solid orange;
}
.Managequiz-container textarea {
  border: 1px solid rgba(5, 41, 73, 0.5);
  padding: 10px;
  resize: none;
  height: 114px;
  width: 100%;
  border-radius: 10px;
}
.dropheader.closed {
  color: rgb(182, 3, 3);
  border: 1px solid rgb(182, 3, 3);
}
img.bottomlogoimg {
  padding: 10px 10px;
  width: 165px;
  background: #052949;
  border-radius: 20px 0px 0px 0px;
}
.btncreatequiz_div {
  position: absolute;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  left: 50%;
  transform: translate(-50%, 0%);
}
.bottomlogoimgdiv {
  margin-top: auto;
  width: 100%;
  position: fixed;
  bottom: 0;
  text-align: right;
  z-index: 2;
  right: 0;
}
.btncreatequiz_div p {
  font-size: 24px;
  font-weight: 600;
  color: #052949;
  margin-bottom: 10px;
}

.btncreatequiz_once {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 30px rgb(0 0 0 / 25%);
  background: #49a8ff !important;
  border: 2px solid #49a8ff !important;
}

.Managequiz-container .search_bar {
  height: fit-content;
  margin-block: auto;
  margin-left: auto;
  width: 380px;
}

.Managequiz-container .search_bar .button_clear {
  right: 10%;
}

.manage-loading {
  position: relative;
  z-index: 100;
  background-color: white;
  height: 50vh;
}

.loading-tr {
  border: none !important;
}

.manage-loading .loading-icon {
  rotate: 360deg;
  animation-name: rotate-spin;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@media screen and (max-width: 1620px) {
  .Managequiz-container {
    max-width: 100%;
  }
}

@media screen and (max-width: 1440px) {
  .managequiz_header h1 {
    font-size: 28px;
  }
  .managequiztable .quiztitle {
    font-size: 17px;
    padding-block: 10px;
  }
  .managequiztable .quiz_toolbox {
    align-items: center;
  }

  .managequiztable td {
    display: table-cell !important;
  }
}
