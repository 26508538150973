.quizreporttable td {
  font-size: 14px;
  height: 39px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}
.quizreporttable tr {
  border-bottom: 1px solid gray;
  transition: all 0.2s ease;
}

.quizreporttable tr:hover {
  background-color: #f2f2f2;
}

.quizreporttable {
  width: 100%;
  border-spacing: 0px;
  border-collapse: collapse;
  margin-top: 15px;
}
.quizreporttable input[type="checkbox"] {
  background-color: black;
  color: blueviolet;
  vertical-align: middle;
  height: 16px;
}

.header_Container {
  border-right: 2px solid rgb(224, 222, 222);
  border-top: 3px solid;
  border-bottom: 3px solid #212529 !important;
}

.quizreporttable th {
  background-color: rgb(247, 243, 243);
  padding-top: 15px;
  border-right: 2px solid rgb(224, 222, 222);
  padding-left: 10px;
  padding-right: 10px;
  vertical-align: middle;
  padding-bottom: 15px;
  font-size: 16px;
  z-index: -1;
  text-align: center;
}

.quizreport_sec .sidebarlinks {
  border-radius: 6px;
  min-height: 50px;
  margin-left: 2%;
  padding-top: 13px;
  text-decoration: none;
  margin-left: 2px;
  color: white;
  font-weight: bold;
  font-size: 1vw;
  height: auto;
  padding: 2%;
  width: 96%;
  vertical-align: middle;
}
.quizreport_sec > div a {
  text-decoration: none;
  margin-left: 4%;
  color: white;
  font-weight: bold;
  font-size: 1.4vw;
  height: auto;
  padding: 2%;
}

.sidebar {
  margin: 0;
  padding-top: 2%;
  width: 21%;
  background-color: #005284;
  height: 100%;
  border-radius: 10px;
  box-shadow: 5px 0 5px -2px #888;
  float: left;
  margin-top: 0%;
  position: fixed;
}

.sidebarsettingsicon {
  height: 30px;
  width: 30px;
  filter: invert(1);
}

.sidebarmainlinks:hover {
  color: white;
}

.sidebarlinks {
  border-radius: 6px;
  height: 50px;
  margin-left: 2%;
  padding-top: 13px;
  width: 96%;
  cursor: pointer;
}

.searchcontainer {
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  align-items: flex-end;
}

.checkbox_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
}
.checkbox_container span {
  text-align: center;
  width: 100%;
}
.container_main {
  height: auto;
  width: 90%;
  padding-top: 1%;
  padding-bottom: 2%;
  margin: auto;
}
.header_Container th span {
  cursor: pointer;
}

.sort-by span {
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
}
.sort-by span svg {
  width: 8px;
  opacity: 0.7;
}

.container_main .formclass {
  min-height: 75vh;
}
.search_bar {
  position: relative;
  outline: 0;
  display: flex;
  float: left;
  box-shadow:
    0 2px 6px rgb(0 0 0 / 29%),
    0 4px 10px rgb(0 0 0 / 0%);
  border-radius: 4px;
  height: 100%;
  border: none;
  flex-direction: row-reverse;
  width: 470px;
}
.search_bar input {
  outline: 0;
  border: none;
  border-radius: 4px;
  padding-left: 15px;
  width: 100%;
}

.search_bar > .textbox {
  outline: 0;
  height: 30px;
  width: 244px;
  line-height: 42px;
  padding: 0 16px;
  background-color: rgba(255, 255, 255, 0.8);
  color: #212121;
  border: 0;
  float: left;
  -webkit-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}

.search_bar > .textbox:focus {
  outline: 0;
  background-color: #fff;
}

.search_bar > .button_search {
  outline: 0;
  background: none;
  background-color: #052949;
  float: left;
  height: 33px;
  width: 42px;
  text-align: center;
  line-height: 42px;
  border: 0;
  color: #fff;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 16px;
  text-rendering: auto;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  -webkit-transition: background-color 0.4s ease;
  transition: background-color 0.4s ease;
  -webkit-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}
.button_search svg {
  height: 20px;
}

.search_bar > .button_search:hover {
  background-color: rgba(0, 90, 150, 0.8);
}

.button_clear {
  position: absolute;
  right: 9%;
  background: none;
  opacity: 0.6;
  top: 6px;
  border: none;
}

.no-results-message {
  display: flex;
  font-size: 22px;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  max-width: 50%;
  word-break: break-all;
  margin: auto;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}
.delete-confirmation-dropdown {
  position: relative;
  display: inline-block;
  margin-right: 20px;
}

.delete-confirmation-content {
  background: #fff;
  border-radius: 5px;
  padding: 10px;
  text-align: left;
  max-width: 580px;
  margin-top: 10px;
  z-index: 999;
}

.delete-confirmation-dropdown:hover .delete-confirmation-content {
  display: block;
}

.delete-confirmation-content p {
  margin: 0 0 15px;
  font-size: 18px;
  font-weight: 500;
}

.delete-confirmation-content button {
  padding: 2px 12px;
  border: none;
  cursor: pointer;
  font-size: 17px;
  border-radius: 5px;
  font-weight: 500;
}

.delete-confirmation-content button:first-child {
  background-color: #3498ff;
  color: #fff;
  margin-right: 10px;
}

.delete-confirmation-content button:last-child {
  border: 2px solid #007bff;
  color: #007bff;
  background-color: #fff;
}

.delete-confirmation-content button:hover {
  opacity: 0.9;
}

.deletepopup_btn {
  display: flex;
  gap: 18px;
  justify-content: flex-end;
}

.delete-confirmation-modal {
  position: absolute;
  top: 19%;
  left: 25%;
  transform: translateX(-50%);
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.delete-confirmation-modal:before {
  content: "";
  position: absolute;
  top: -10px;
  left: 20%;
  transform: translateX(-50%);
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #ffffff transparent;
}

.btn.btn-danger {
  background-color: #dc3545;
  color: #fff;
  padding-inline: 0.75rem;
  padding-block: 0.375rem;
  font-size: 1rem;
  border: 1px solid #dc3545;
}

.dropdown-main {
  position: relative;
  display: inline-block;
}

.dropdown-main .dropdown-div {
  position: relative;
}

.dropdown-main .dropdown-item {
  position: absolute;
  background-color: white;
  width: 178px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  z-index: 10;
  min-width: fit-content;
}

.dropdown-main .drop-btn {
  white-space: nowrap;
  padding: 5px;
  padding-inline: 10px;
  cursor: pointer;
  border: 0.5px solid #ccc;
  font-size: 16px;
  font-weight: 500;
  max-width: 70px;
  background-color: #f2f2f2;
  overflow: hidden;
}

.dropdown-main .drop-btn .search-calender {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
}
.dropdown-main .dropdown-item .dp-item {
  color: black;
  padding: 5px;
  padding-inline: 10px;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  white-space: nowrap;
  font-weight: normal;
  transition: all 0.2s ease;
  border-bottom: 1px solid #ccc;
}
.dropdown-main .dropdown-item .dp-item.active {
  background-color: #a5d4ff;
}

.dropdown-main .dropdown-item .dp-item:hover {
  background-color: #b4b4b4;
}

.dropdown-main .calendar-div {
  position: absolute;
  background-color: white;
  width: fit-content;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.dropdown-main .calendar-div .action-btn {
  display: flex;
  justify-content: right;
  align-items: right;
  gap: 10px;
  padding: 5px;
  padding-inline: 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  background-color: #f2f2f2;
  overflow: hidden;
}

.dropdown-main .calendar-div .rdrMonth {
  padding-bottom: 0.6vw;
}

.dropdown-main .dropdown-item .abs-dt-forward {
  float: right;
}

/* .dropdown-main .calendar-div .action-btn .back-btn, .dropdown-main  */
.calendar-div .action-btn .apply-btn {
  padding: 5px;
  padding-inline: 10px;
  cursor: pointer;
  border: 0.5px solid #ccc;
  font-size: 14px;
  font-weight: 400;
  background-color: #f2f2f2;
  overflow: hidden;
  transition: all 0.2s ease;
}

.dropdown-main .calendar-div .action-btn .back-btn {
  border: none;
}
/* .dropdown-main .calendar-div .action-btn .back-btn:hover, .dropdown-main  */
.calendar-div .action-btn .apply-btn:hover {
  background-color: #b4b4b4;
}

.report-delete {
  position: absolute;
  background-color: #d4edda;
  color: #155724;
  padding-inline: 0.75rem;
  padding-block: 0.375rem;
  font-size: 1rem;
  border: 1px solid #c3e6cb;
  border-radius: 5px;
  border-color: #155724;
  font-weight: 500;
  cursor: pointer;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.report-delete:hover {
  background-color: #c3e6cb;
  border-color: #155724;
}

.report-delete .close-report-delete {
  margin-left: 20px;
}

.result-loading:hover {
  background-color: white !important;
}

.dropdown-main {
  position: relative;
  display: inline-block;
}

.dropdown-main .dropdown-div {
  position: relative;
}

.dropdown-main .dropdown-item {
  position: absolute;
  background-color: white;
  width: 178px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  z-index: 10;
  min-width: fit-content;
}

.dropdown-main .drop-btn {
  white-space: nowrap;
  padding: 5px;
  padding-inline: 10px;
  cursor: pointer;
  border: 0.5px solid #ccc;
  font-size: 16px;
  font-weight: 500;
  max-width: 70px;
  background-color: #f2f2f2;
  overflow: hidden;
}

.dropdown-main .drop-btn .search-calender {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
}
.dropdown-main .dropdown-item .dp-item {
  color: black;
  padding: 5px;
  padding-inline: 10px;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  white-space: nowrap;
  font-weight: normal;
  transition: all 0.2s ease;
  border-bottom: 1px solid #ccc;
}
.dropdown-main .dropdown-item .dp-item.active {
  background-color: #a5d4ff;
}

.dropdown-main .dropdown-item .dp-item:hover {
  background-color: #b4b4b4;
}

.dropdown-main .calendar-div {
  position: absolute;
  background-color: white;
  width: fit-content;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.dropdown-main .calendar-div .action-btn {
  display: flex;
  justify-content: right;
  align-items: right;
  gap: 10px;
  padding: 5px;
  padding-inline: 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  background-color: #f2f2f2;
  overflow: hidden;
}

.dropdown-main .calendar-div .rdrMonth {
  padding-bottom: 0.6vw;
}

.dropdown-main .dropdown-item .abs-dt-forward {
  float: right;
}

/* .dropdown-main .calendar-div .action-btn .back-btn, .dropdown-main  */
.calendar-div .action-btn .apply-btn {
  padding: 5px;
  padding-inline: 10px;
  cursor: pointer;
  border: 0.5px solid #ccc;
  font-size: 14px;
  font-weight: 400;
  background-color: #f2f2f2;
  overflow: hidden;
  transition: all 0.2s ease;
}

.dropdown-main .calendar-div .action-btn .back-btn {
  border: none;
}
/* .dropdown-main .calendar-div .action-btn .back-btn:hover, .dropdown-main  */
.calendar-div .action-btn .apply-btn:hover {
  background-color: #b4b4b4;
}

.report-delete {
  position: absolute;
  background-color: #d4edda;
  color: #155724;
  padding-inline: 0.75rem;
  padding-block: 0.375rem;
  font-size: 1rem;
  border: 1px solid #c3e6cb;
  border-radius: 5px;
  border-color: #155724;
  font-weight: 500;
  cursor: pointer;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.report-delete:hover {
  background-color: #c3e6cb;
  border-color: #155724;
}

.report-delete .close-report-delete {
  margin-left: 20px;
}

.result-loading:hover {
  background-color: white !important;
}

.searchcontainer .disable {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.5;
}

.report-delete {
  position: absolute;
  background-color: #d4edda;
  color: #155724;
  padding-inline: 0.75rem;
  padding-block: 0.375rem;
  font-size: 1rem;
  border: 1px solid #c3e6cb;
  border-radius: 5px;
  border-color: #155724;
  font-weight: 500;
  cursor: pointer;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.report-delete:hover {
  background-color: #c3e6cb;
  border-color: #155724;
}

.report-delete .close-report-delete {
  margin-left: 20px;
}

@media screen and (max-width: 1440px) {
  .main_heading {
    font-size: 24px;
  }
  .search_bar {
    width: 400px;
  }
  .btnquiz {
    font-size: 16px !important;
  }
  .quizreporttable td {
    margin-block: 6px;
  }
}
