/* quiz_navbar container */
.quiz_navbar {
  display: flex;
  align-items: center;

  background-color: #a5d4ff;
  color: white;
  height: 74px;
}
.quiz_navbar {
  color: rgba(5, 41, 73, 0.7);
  transition:
    background-color 0.2s,
    color 0.2s;
  font-size: 1.2vw;
  font-weight: 600;
}
.quiz_navbar_managequiz {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 50%;
  justify-content: flex-end;
}

.quiz_navbar_main {
  display: flex;
  align-items: center;
  background-color: #a5d4ff;
  padding-right: 30px;
  box-shadow: 0px 9px 17px rgba(0, 0, 0, 0.25);
  color: white;
  height: 74px;
  justify-content: space-between;
}
.fixed {
  /* position: fixed; */
  width: 100%;
  z-index: 10;
}
/* Logo on the left */

.quiz_navbar-header {
  background: #052949;
  padding: 5.8%;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: 20px;
}
.quiz_navbar-header a img {
  padding-left: 20px;
}

/* Centered navigation options */
.quiz_nav-list {
  list-style: none;
  display: flex;
  padding: 0;
  height: 100%;
  margin: 0;
}
.quiz_navbarright {
  padding: 10px 20px;
  display: flex;
  align-items: baseline;
}
.quiz_navbar-right {
  float: right;
  width: fit-content;
  display: flex;
}
.quiz_navbar-left {
  display: flex;
  justify-content: flex-end;
  height: 100%;
}
.quiz_nav-item {
  margin-right: 40px;
  font-size: 24px;
  font-weight: 600;
  height: 100%;
  color: #052949 !important;
  cursor: pointer;
  text-decoration: none !important;
  white-space: nowrap;
}
.quiz_nav-item a {
  color: #052949 !important;
  cursor: pointer;
  padding-bottom: 4px;
  text-decoration: none !important;
}

a[disabled] {
  pointer-events: none;
}

.active_nav,
#active_nav {
  background: linear-gradient(
      90deg,
      rgb(102, 136, 181) 0%,
      rgb(5, 41, 73) 51%,
      rgb(102, 136, 181) 100%
    )
    center bottom / 100% 4px no-repeat;
  background-repeat: no-repeat;
  color: inherit;
}

/* Buttons on the right */
.nav-button {
  border: 1.4px solid #052949;
  border-radius: 12px;
  height: 40px;
  padding: 5px 15px 5px 15px;
  font-size: 20px;
  font-weight: 600;
  color: #052949;
  background-color: transparent;
  white-space: nowrap;
}

.nav-button:last-child {
  margin-left: 10px;
}

.navbar_btns {
  display: flex;
  gap: 15px;
  margin-right: 10px;
}

.upgradebtn {
  color: #ffffff;
  font-weight: 600;
  background: #052949;
  border: 2px solid #052949;
  white-space: nowrap;
  border-radius: 12px;
  text-decoration: none;
  font-size: 18px;
  height: 54px;
  padding: 4px 20px 4px 20px;
}
.aupgradebtn {
  text-decoration: none;
}

.dropdown-content {
  margin-top: 9px;
  margin-left: 0%;
  margin-right: 10px;
  right: -5px;
  z-index: 1;
  display: none;
  width: 270px;
  position: absolute;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  top: 42px;
  padding: 10px;
  border-radius: 6px;
  flex-direction: column;
}
.dropdown-content1 a {
  color: black;
  padding: 8px 16px;
  text-decoration: none;
  display: block;
}

.profileanchor:hover + .dropdown-content {
  display: flex !important;
}
.dropdown-content:hover {
  display: flex !important;
}

.profileanchor p img {
  width: 14px;
  float: right;
  margin-left: 1%;
}

.profileanchor p #display_name {
  font-size: 16px;
  font-weight: 600;
  width: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profileanchor p {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0px 10px;
  margin: 0px;
  border-left: 1px solid white;
}

.User_dropdown {
  list-style: none;
  height: 50px;
}
.dropdown-content a {
  color: #333;
  text-decoration: none;
  transition:
    background-color 0.2s,
    color 0.2s;
  width: 100% !important;
  font-size: 0.8vw;
  padding-left: 10px;
  height: 35px;
  align-items: center;
  display: flex;
}
.dropdown-content a:hover {
  color: rgb(26, 109, 253);
  background-color: whitesmoke;
}

#dropdown-content-analysis {
  display: block;
  position: absolute;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 230px;
  border-radius: 5px;
}

.analysis_links {
  color: black;
  padding: 5px;
  text-decoration: none;
  display: block;
  font-size: 20px;
  padding-bottom: 10px;
  border-radius: 5px;
  padding-left: 10px;
}
.analysis_links:hover {
  background-color: #005284;
  color: white !important;
}
#analysislabel {
  cursor: pointer;
  display: flex;
  align-items: baseline;
  gap: 10px;
}

@media only screen and (max-width: 1480px) {
  .quiz_nav-item {
    font-size: 20px;
    margin-right: 20px;
  }
}
