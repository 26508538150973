.myTeamColumnOutside {
  width: 100%;
  min-width: 70rem;
  border: 1px solid rgb(206, 206, 206);
  height: auto;
  padding: 10px;
  margin-top: 30px !important;
}

.myTeamColumn {
  margin-bottom: 25% !important;
}

.d-table-cell {
  text-align: center;
}

.getStartedBtn {
  padding: 0px 12px !important;
}

.myTeamCloseIcon {
  width: 28px;
  background-color: white;
  border-radius: 100%;
  position: absolute;
  right: 38px;
  top: 15.5%;
  cursor: pointer;
}

.inner-area h4 b {
  background-color: transparent;
}

#myteam_popup {
  padding: 0px !important;
  inset: 0px !important;
  margin: 0px !important;
}

.d-table {
  margin-top: 20px;
}

.d-table svg {
  right: 12px;
  top: 41px;
  font-size: 33px;
}
.d-table svg:hover {
  transform: scale(1.1);
  color: rgb(167, 0, 0);
}

@media screen and (max-width: 1440px) {
  .myTeamCloseIcon {
    right: 4px;
    top: 18%;
  }
}

/* temp css */

/* styles.css */

.column {
  width: 1129px !important;
  height: 653px !important;
  padding: 10px;
  padding-left: 5%;
  padding-right: 5%;
  margin-top: 1%;
  margin: 0 auto;
}

.modal_dialog1,
.modal_dialog2,
.modal_dialog3 {
  position: absolute;
  top: 40% !important;
  left: 47% !important;
  transform: translate(-47%, -45%);
  margin: 45px auto 0;
}

.wrapper {
  max-width: 1090px;
  width: 100%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  color: #052949;
  justify-content: space-between;
}

.wrapper .table {
  background: #fff;
  width: calc(33% - 20px);
  padding: 21px 0px 0px 0px;
  position: relative;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  border-radius: 20px;
}

.table .price-section {
  display: flex;
  justify-content: center;
}

.table .price-area {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  padding: 2px;
}

.table {
  width: 297px;
  height: 340px;
}

.aj_p {
  text-align: center;
}

p.aj_des {
  color: grey;
  padding-bottom: 30px;
  font-size: 14px;
}

.table.premium {
  border: 2.5px solid #052949;
  box-shadow: 0px 23px 30px rgba(5, 41, 73, 0.38);
  border-radius: 20px;
  background: #fff !important;
}

.table.ultimate {
  border: 2.5px solid #a05919;
  box-shadow: 0px 23px 30px rgba(160, 89, 25, 0.38);
  border-radius: 20px;
}

.premium_all {
  padding-top: 22px;
}

.price-area .inner-area {
  height: 100%;
  width: 100%;
  line-height: 117px;
  text-align: center;
  color: #fff;
  position: relative;
}

.price-area .inner-area .text {
  font-size: 25px;
  font-weight: 400;
  position: absolute;
  top: -10px;
  left: 5px;
}

.price-area .inner-area .price {
  font-size: 45px;
  font-weight: 500;
  margin-left: 16px;
}

.table .package-name {
  width: 100%;
  height: 2px;
  margin: 35px 0;
  position: relative;
}

.myteam_container {
  padding-left: 40px;
  padding-right: 40px;
  height: auto;
}

#h5_myteams {
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.01em;
  color: #052949;
  margin-top: 17px;
}

#h5save {
  color: #4caf50;
  margin-left: 1%;
  font-size: 16px;
  margin-right: -100px;
}

.table .features_myteams li {
  margin-bottom: 15px;
  list-style: none;
  display: flex;
  justify-content: space between;
}

.features_myteams li .list-name {
  font-size: 17px;
  font-weight: 400;
}

.features_myteams li .icon {
  font-size: 15px;
}

.features_myteams li .icon.check {
  color: #2db94d;
}

.features_myteams li .icon.cross {
  color: #052949;
}

.table .btn {
  width: 100%;
  display: flex;
}

.table .btn button {
  width: 80%;
  height: 50px;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  border: none;
  outline: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.basic ::selection,
.basic .price-area,
.basic .inner-area,
.basic .head_tab h2 {
  color: #052949;
}

.basic .btn button {
  color: #a05919;
  margin-top: -75px;
  border: 3px solid #a05919;
  filter: drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.25));
  border-radius: 20px;
}

.premium ::selection,
.premium .price-area,
.premium .inner-area,
.premium .head_tab h2 {
  color: #052949;
}

.premium .btn button {
  background: #a05919;
  color: #fff;
  margin-top: -123px;
  border: 3px solid #a05919;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

.ultimate ::selection,
.ultimate .price-area,
.ultimate .inner-area,
.ultimate .head_tab h2 {
  color: #052949;
}

.ultimate .btn button {
  background: #a05919;
  color: #fff;
  margin-top: -123px;
  border: 3px solid #a05919;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

@media (max-width: 756px) {
  .wrapper .table {
    width: 100%;
  }

  .table.premium {
    margin: 40px 0px;
  }
  .table.ultimate {
    margin: 0px;
  }
}

::selection {
  color: #fff;
}

.table .ribbon {
  width: 150px;
  height: 150px;
  position: absolute;
  top: -10px;
  left: -10px;
  overflow: hidden;
}

.table .ribbon::before,
table .ribbon::after {
  position: absolute;
  content: "";
  z-index: -1;
  display: block;
  border: 7px solid #e09524;
  border-top-color: transparent;
  border-left-color: transparent;
}

.table .ribbon::before {
  top: 0px;
  right: 15px;
}

.table .ribbon::after {
  bottom: 15px;
  left: 0px;
}

.table .ribbon span {
  position: absolute;
  top: 30px;
  right: 0;
  transform: rotate(-45deg);
  width: 200px;
  background: #e09524;
  padding: 6px 0;
  font-weight: 700;
  color: #fff;
  text-align: center;
  font-size: 17px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.12);
}

.table.basic {
  border: 2.5px solid #a5d4ff;
  box-shadow: 0px 23px 30px rgb(165 212 255 / 47%);
  border-radius: 20px;
}

.btntoogle {
  color: #052949;
  margin: 4px 0px 4px -2px;
  border: none;
}

.monthly {
  background: none;
  margin: 0px 23px 0px 16px;
}

.anually {
  background: #a5d4ff;
  border-radius: 13px;
  padding: 6px 17px 6px 26px;
}

.anuallymonthlydiv {
  width: 222px;
  display: inline-flex;
  border: 2px solid #052949;
  border-radius: 15px;
  font-size: 16px;
}

.toogletimeoptions {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2%;
  margin-bottom: 6%;
}

#parent {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2%;
  margin-bottom: 3%;
}

.parentbtn {
  border: 3px solid #052949;
  filter: drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.25));
  border-radius: 15px;
  background: #052949;
  color: #fff;
  font-size: 16px;
  padding: 8px 15px 8px 15px;
}

.aj_p {
  margin-top: 28px;
}

.basic .btn button:hover {
  background: #a05919;
  color: #fff;
}

.d-table {
  display: table;
}

.d-table-cell {
  display: table-cell;
}

.w-100 {
  width: 100%;
}

.tar {
  text-align: right;
}

.modal-close {
  border: none;
  background: #fff;
  font-size: 32px;
}
