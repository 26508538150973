.essay-auto-grading {
}

.essay-auto-grading .explanation-head {
  margin-bottom: 5px;
}

.essay-auto-grading .explanation-head.auto-checkbox {
  display: flex;
  align-items: center;
}

.essay-auto-grading .explanation-head .auto-check {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  transform: scale(1);
}

.essay-auto-grading .explanation-body .prem-feature {
  color: red;
  display: block;
}

.essay-auto-grading .explanation-body {
  border: none;
}

.essay-auto-grading .explanation-body .explain-essay-grading {
  word-break: normal;
  margin-block: 10px;
}

.essay-auto-grading .grading-get .explanation-head {
  font-weight: 600;
  margin-top: 30px;
}

.essay-auto-grading .plus-point,
.essay-auto-grading .minus-point {
  display: flex;
  gap: 20px;
  margin-block: 20px;
}
.essay-auto-grading .plus-point input,
.essay-auto-grading .minus-point input {
  height: 30px;
  border: 1px solid #c3c3c3;
  font-size: 16px;
  padding-left: 5px;
}

.essay-auto-grading .plus-point input[type="number"],
.essay-auto-grading .minus-point input[type="number"] {
  width: 80px;
  text-align: center;
  padding-left: 20px;
}

.essay-auto-grading .add-more-btn {
  cursor: pointer;
  color: #002653;
}
.essay-auto-grading .add-more-btn:hover {
  text-decoration: underline;
}

.essay-auto-grading .max-min-score-set {
  text-align: end;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.essay-auto-grading .max-min-score-set .max-score,
.essay-auto-grading .max-min-score-set .minus-point {
  /* margin-block: 20px; */
}

.essay-auto-grading .max-min-score-set input {
  width: 50px;
  text-align: center;
  border: 1px solid #c3c3c3;
}
