.take-common-questions {
  width: 100%;
  margin-inline: auto;
  margin-top: 50px;
}

.take-common-questions .question-title {
  font-size: 24px;
  padding-left: 25px;
  color: #6c6c6c;
  font-size: 24px;
  word-wrap: break-word;
  margin: 0 auto;
  text-align: left;
  width: 89%;
  margin-top: 8%;
  margin-left: 84px;
}
.take-common-questions .question-title pre {
  white-space: break-spaces;
  font-weight: 600;
  font-family: "Inter", sans-serif !important;
}

.take-common-questions .options-div {
  padding-inline: 20px;
  margin-top: 30px;
  margin-left: 20px;
}

.take-common-questions .options-div .options {
  display: flex;
  gap: 20px;
  /* padding-block: 15px; */
  margin-block: 15px;
  align-items: center;
  /* border: 1px solid #3382c8; */
  border-radius: 10px;
  padding-inline: 20px;
  cursor: pointer;
}

.take-common-questions .otp-number {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #3382c8;
  padding: 16px;
  width: 27px;
  height: 27px;
  cursor: pointer;
  color: #3382c8;
  transition: 0.2s;
}

.take-common-questions .multi-response {
  border-radius: 6px;
}

.take-common-questions .options-div .option {
  width: 100%;
  padding-block: 12px;
  cursor: pointer;
  border: 1px solid #3382c8;
  padding-inline: 20px;
  border-radius: 10px;
  transition: 0.2s;
}

.take-common-questions .options-div .option pre {
  white-space: break-spaces;
  margin-block: 0;
}

.take-common-questions .options-div .choice {
  display: none;
}

.take-common-questions
  .options-div
  .options
  input[type="radio"]:checked
  + label {
  background-color: #3382c8;
  color: white;
}

.take-common-questions
  .options-div
  .options
  input[type="radio"]:checked
  + label
  + label {
  background-color: #ebf2f9 !important;
}

.take-common-questions
  .options-div
  .options
  input[type="checkbox"]:checked
  + label {
  background-color: #3382c8;
  color: white;
}

.take-common-questions
  .options-div
  .options
  input[type="checkbox"]:checked
  + label
  + label {
  background-color: #ebf2f9;
}

.take-common-questions .btn-submit {
  background-color: #3382c8;
  border: none;
  color: white;
  padding-block: 10px;
  font-size: 18px;
  padding-inline: 20px;
  float: right;
  margin-right: 41px;
  outline: none;
  margin-top: 0px;
  margin-bottom: 30px;
  transition: 0.2s;
}

.take-common-questions .btn-submit.btn-disable {
  pointer-events: none;
  background-color: #05294999;
}

.take-common-questions .btn-submit:hover {
  box-shadow: 0px 0px 10px #3382c8;
}

.take-common-questions .question-explanation {
  padding-inline: 20px;
  margin-top: 30px;
  width: 92%;
  margin-left: 71px;
  padding-left: 41px;
  margin-bottom: 20px;
}

.take-common-questions .question-explanation .explanation-title {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 10px;
}

.take-common-questions .question-explanation .explanation-body {
  border: 1px solid #00000040;
  padding: 20px;
  border-radius: 6px;
}

.take-common-questions .word-limit {
  font-size: 18px;
  font-weight: 500;
  margin-top: 10px;
  text-align: end;
  visibility: hidden;
  width: 91%;
  margin-left: 71px;
  margin-block: 20px;
}

.take-common-questions .options-div .essay-question {
  font-size: 18px;
  padding: 15px;
  height: 300px;
  width: 100%;
  border-radius: 10px;
  width: 91.5%;
  padding: 15px;
  font-size: 20px;
  margin-left: 71px;
  background-color: #f7f7f7;
  border-radius: 10px;
  border: 1px solid rgb(149 149 149 / 50%);
  height: 250px;
  display: block;
  overflow-y: auto;
}

.essay-question span {
  background-color: transparent !important;
}

.take-common-questions .between-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .options-div .textarea {
    width: 85%;
    padding: 15px;
    font-size: 20px;
    background-color: #f7f7f7;
    border-radius: 10px;
    border: 1px solid rgb(149 149 149 / 50%);
    resize: none;
    margin: auto;
    display: block;
} */

.quizcontainer {
  margin-top: 4%;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.take-common-questions .upload-file {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.take-common-questions .upload-file #upload-file {
  display: none;
}

.take-common-questions .upload-file .file-label {
  border: 1px solid #3382c8;
  padding-inline: 30px;
  padding-block: 10px;
  background-color: #002653;
  color: white;
  cursor: pointer;
  transition: 0.2s;
  border-radius: 6px;
  max-width: 225px;
  width: fit-content;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.take-common-questions .upload-file .file-label:hover {
  box-shadow: 0px 0px 10px #002653;
}

.take-common-questions .upload-file .size-limit {
  color: grey;
}

.take-common-questions .upload-file .large-file-error {
  color: red;
  margin-block: 10px;
}

.take-common-questions .upload-file .file-type {
  margin-top: 10px;
}

.take-common-questions .description {
  border: none !important;
  padding-left: 0px !important;
}

.set-loading {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #0000003b;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
}

@media screen and (max-width: 1500px) {
  .quizcontainer {
    width: 90%;
  }
  .main-quiz-take-navbar .navbar-content .time-remaining {
    white-space: nowrap;
  }
  .main-quiz-take-navbar .navbar-content .question-title {
    white-space: nowrap;
  }
  .take-common-questions .question-title {
    font-size: 22px;
    font-weight: 500;
  }
}

@media screen and (max-width: 1200px) {
  .quizcontainer {
    width: 95%;
  }
  .take-common-questions {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .take-common-questions .question-title {
    font-size: 20px;
    font-weight: 500;
    margin-left: 0px;
    margin-top: 0px;
  }

  .take-common-questions .options-div .options {
    padding-inline: 0px;
  }

  .take-common-questions .options-div {
    margin-left: 0px;
    margin-top: 0px;
    width: 100%;
  }

  .take-common-questions .options-div .essay-question {
    margin-left: 0px;
    width: 100%;
  }

  .take-common-questions .btn-submit {
    margin-top: 0px;
    align-self: flex-end;
    margin-right: 20px;
  }
  .takequiztittle .first-takequiztitle {
    width: 100%;
  }
  .takequiztittle .first-takequiztitle img {
    max-width: 100%;
  }
  .take-common-questions .question-explanation {
    margin-bottom: 20px;
    width: 100%;
    margin-left: 0px;
    padding-left: 20px;
  }
}

@media screen and (max-width: 768px) {
  .main-quiz-take-navbar .navbar-content {
    width: 95%;
    justify-content: space-between;
  }
  .main-quiz-take-navbar .navbar-content .time-remaining {
    white-space: normal;
    width: fit-content;
    font-size: 15px;
  }
  .progress_container {
    display: none;
  }
  .take-common-questions .word-limit {
    width: 100%;
    margin-left: 0px;
  }
  .main-quiz-take-navbar .navbar-content .question-title {
    white-space: normal;
    width: fit-content;
    font-size: 15px;
  }
  .take-common-questions .between-img img {
    width: 100%;
  }
}

@media screen and (max-width: 425px) {
  .take-common-questions {
    margin-top: 0px;
  }
  .quizcontainer {
    margin-inline: auto;
    width: 90%;
  }
  .quizcontainer > .quizcontainer {
    margin-inline: auto;
    width: 100%;
  }
  .take-common-questions .question-title {
    width: 100%;
    margin-left: 0px;
    margin-top: 0px;
    padding-left: 0px;
  }
  .take-common-questions .options-div {
    padding-inline: 0px;
    width: 100%;
  }
  .take-common-questions .btn-submit {
    margin-right: 0px;
  }
  .take-common-questions .btn-submit {
    align-self: center;
    margin-top: 20px;
  }
  .main-quiz-take-navbar .navbar-content {
    flex-direction: column;
    gap: 5px;
  }

  .quizStartPageMain .instructions .child-instruct h1 {
    font-size: 18px;
  }
  .quizStartPageMain .instructions .child-instruct p {
    font-size: 15px;
  }
  .quizStartPageMain .instructions .child-instruct .start-quiz-btn {
    font-size: 18px;
  }
  .quizStartPageMain .instructions .child-instruct {
    padding: 20px;
  }
  .take-common-questions .question-explanation {
    margin-block: 0px;
    padding-inline: 0px;
  }
}
