.select-file-upload-type {
  width: 300px;
  background-color: #f0f8ff;
  border: 1px solid #dee2e7;
  height: 100vh;
}

.select-file-upload-type .upload-title {
  font-size: 16px;
  font-weight: 600;
  padding-block: 14px;
  text-align: center;
  border-bottom: 1px solid #dee2e7;
}

.select-file-upload-type .upload-container {
}

.select-file-upload-type .upload-info,
.select-file-upload-type .file-type,
.select-file-upload-type .upload-type-select,
.select-file-upload-type .available-file-format {
  width: 90%;
  margin-inline: auto;
  margin-block: 20px;
}
.select-file-upload-type .upload-info {
  font-weight: 500;
}

.select-file-upload-type .upload-type-select {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 12px;
}

.select-file-upload-type .upload-type-select .selected-items {
  border: 1px solid #dee2e7;
  width: fit-content;
  padding-inline: 10px;
  padding-block: 5px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  font-size: 16px;
}
.select-file-upload-type .upload-type-select .selected-items > svg {
  margin-left: 5px;
  cursor: pointer;
}

.select-file-upload-type .file-type select {
  /* margin-left: 16px; */
  font-size: 16px;
}

.select-file-upload-type .available-file-format .checkbox-div {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 12px;
}

.select-file-upload-type .available-file-format .checkbox-div input {
  width: 16px;
  height: 16px;
}
