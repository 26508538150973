.main-quiz-take-navbar {
  width: 100%;
  padding: 15px 0;
  /* position: fixed; */
  min-height: 50px;
  top: 0;
  background-color: #052949;
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 0.02em;
  color: #fff;
}

.take-common-questions .options-div .options label {
  font-size: 15px;
}

.main-quiz-take-navbar .navbar-content {
  width: 90%;
  margin-inline: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 0 auto;
  font-size: 18px;
  align-items: center;
  gap: 20px;
}

.main-quiz-take-navbar .navbar-content .question-title {
  font-size: 18px;
  color: white;
  font-weight: 600;
}

.main-quiz-take-navbar .progress {
  display: flex;
  align-items: center;
  border-radius: 20px;
  width: 70%;
  background-color: white;
  max-width: 600px;
  border: 0.2px solid;
  margin-left: 6%;
  height: 20px;
}

.main-quiz-take-navbar .progress .origin-progress-bar {
  width: 100%;
  background-color: #ddd;
  height: auto;
  border-radius: 6px;
  display: inline-block;
  position: relative;
}

.main-quiz-take-navbar .total-questions {
  position: absolute;
  font-size: 13px;
  color: black;
  margin: 0 auto;
  z-index: 99;
  top: -9px;
  left: 0px;
  width: -moz-fit-content;
  width: fit-content;
  right: 0;
}

.main-quiz-take-navbar .progress .progress-bar {
  position: absolute;
  background-color: #15ad13;
  height: -moz-fit-content;
  height: fit-content;
  top: -10px;
  left: -1px;
  border: 0.2px solid white;
  border-radius: 6px;
  text-align: center;
  height: 20px;
}

.main-quiz-take-navbar .percent-complete {
  color: white;
  line-height: 0px;
}

.main-quiz-take-navbar .navbar-content .time-remaining {
  font-size: 18px;
  color: white;
  font-weight: 600;
  width: 300px;
}

.progress_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  width: 56%;
  align-items: center;
}

@media screen and (max-width: 425px) {
  .main-quiz-take-navbar {
    padding: 7px 0;
  }
}
