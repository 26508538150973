.main-error-notification {
  display: flex;
  align-items: center;
  text-align: start;
  background-color: #f8d7da;
  padding-inline: 20px;
  width: 75%;
  margin-inline: auto;
  margin-block: 10px;
  border: 1px solid #f5c2c7;
  border-radius: 5px;
  visibility: hidden;
  height: 44px;
}

.main-error-notification .notification-title {
  color: #842029;
  font-weight: 500;
  margin-block: 12px;
}

.main-error-notification.visible {
  visibility: visible;
}

.main-error-notification .notify-close {
  margin-left: auto;
  color: #842029;
  cursor: pointer;
  width: fit-content;
}
