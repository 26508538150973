.question-main-container {
  width: 75%;
  max-width: 75%;
  min-height: 100px;
  margin-inline: auto;
}

.question-main-container .question-title,
.question-main-container .upload-img,
.question-main-container .explanation {
  margin-block: 20px;
}

.question-main-container .explanation {
  width: 100%;
  margin-top: 25px;
}

.question-main-container .question-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.question-main-container .question-delete-icon {
  cursor: pointer;
}
.question-main-container .question-title-head {
  color: #4b4646;
  font-size: 16px;
  margin-bottom: 14px;
  font-weight: 500;
  display: none;
}

.question-main-container .question-title-head .total-questions-of {
  float: right;
}

.question-main-container .question-title-body {
  border: 1px solid #c3c3c3;
  padding-block: 12px;
  padding-inline: 15px;
  border-radius: 6px;
  font-size: 24px;
  width: 95%;
  max-width: 95%;
  min-width: 95%;
  outline: none;
}

.question-main-container .question-title-body pre {
  white-space: break-spaces;
  margin: 0px;
}

.question-main-container .question-title-body:active,
.question-main-container .question-title-body:hover {
  box-shadow: 0px 0px 10px #c3c3c3;
}

.question-main-container .btn-with-img {
  padding-inline: 20px;
  font-size: 16px;
  width: fit-content;
  padding-block: 10px;
  border-radius: 25px;
  border: 1px solid #0a2948;
  color: white;
  background-color: #002653;
  cursor: pointer;
  transition: 0.2s;
  font-weight: 600;
}

.btn-with-img {
  display: flex;
  text-align: start;
  align-items: center;
  max-width: 350px;
}
.question-main-container .btn-with-img > svg {
  margin-right: 10px;
}

.question-main-container .btn-with-img:hover {
  box-shadow: 0 0 10px #3e4e5f;
}

.question-main-container .upload-img .upload-img-div {
  position: relative;
  text-align: center;
  width: fit-content;
  margin-inline: auto;
}

.question-main-container .upload-img .upload-img-div .btn-remove-img {
  position: absolute;
  right: 0;
  font-size: 18px;
  background-color: #002653;
  color: white;
  outline: none;
  border: none;
  padding-inline: 12px;
  padding-block: 5px;
  border-radius: 20px;
  border: 1px solid white;
  margin: 7px;
}

.question-main-container .upload-img .upload-img-div .btn-remove-img:hover {
  box-shadow: 0 0 10px #3e4e5f;
}

.question-main-container .upload-img .question-image {
  max-width: 100%;
  width: 800px;
  margin-inline: auto;
}
.question-main-container .upload-img input:hover {
  box-shadow: 0 0 10px #3e4e5f;
}

.question-main-container .question-choices {
  margin-top: 40px;
}

.question-main-container .question-choices-head {
  height: fit-content;
}
.question-main-container .question-choices-head .wordLimit {
  width: 60px;
}

.question-main-container .question-choices-head {
  font-size: 16px;
  color: #4b4646;
  margin-bottom: 14px;
  font-weight: 500;
  margin-top: 30px;
}
.question-main-container .question-choices-body .choice {
  width: 100%;
  min-height: 50px;
  height: fit-content;
  display: flex;
  align-items: center;
  margin-block: 20px;
}
.question-main-container .question-choices-body .choice > span {
  width: 100%;
  min-height: 50px;
  width: 95%;
  padding-inline: 15px;
  padding-block: 10px;
  border: 1px solid #c3c3c3;
  border-radius: 6px;
  display: flex;
  align-items: center;
  margin-right: 30px;
}
.question-main-container .question-choices-body .choice .btn-radio {
  width: 16px;
  height: 16px;
  margin-right: 10px;
  margin-block: 0px;
  transform: scale(1);
}

input[type="radio"]:checked > .mySpan {
  width: 100px;
  height: 100px;
  background-color: green;
}

.question-main-container .question-choices-body .input-ans {
  display: flex;
  flex-direction: column;
  max-width: 95%;
  min-width: 80%;
  min-height: 100%;
  font-size: 18px;
  border: 0px;
  outline: none;
  word-break: break-all;
  background-color: transparent;
}

.question-main-container .question-choices-body .input-ans pre {
  white-space: break-spaces;
  margin: 0px;
}

.question-main-container .question-choices-body .essay-ans {
  width: 100%;
  font-size: 18px;
  padding: 16px;
  border-radius: 6px;
  resize: none;
}

.question-main-container .question-choices-body .trash {
  color: #002653;
  cursor: pointer;
}

.question-main-container .question-choices-body .active {
  background-color: green;
  color: white;
}

.question-main-container .explanation-head {
  margin-bottom: 14px;
  color: #4b4646;
  font-weight: 500;
}

.question-main-container .explanation-body {
  border: 1px solid #c3c3c3;
  padding-inline: 15px;
  padding-block: 10px;
  border-radius: 6px;
  min-height: 70px;
  outline: none;
}

.question-main-container .explanation-body pre {
  white-space: break-spaces;
  margin: 0px;
}

.float-me-right {
  float: right;
}

.type-file-upload {
  display: none;
}

.main-loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes rotate-spin {
  0% {
    rotate: 0deg;
  }
  100% {
    rotate: 360deg;
  }
}
.main-loading .loading-icon {
  rotate: 360deg;
  animation-name: rotate-spin;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.question-main-container .question-title-required {
  color: red;
  font-size: 16px;
}
