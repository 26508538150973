.main-end-quiz {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  align-items: center;
  padding-top: 6%;
  gap: 25px;
}

.main-end-quiz .custom-message {
  font-weight: 400;
  font-size: 29px;
  color: #034077;
  width: 90%;
  text-align: center;
}

.main-end-quiz .custom-message pre {
  white-space: break-spaces;
}

.main-end-quiz .pass-fail {
  font-size: 29px;
  max-width: 80%;
  text-align: center;
}

.main-end-quiz .show-score {
  font-size: 29px;
}

.main-end-quiz .next-quiz {
  width: 500px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid #c7e2ff;
  border-radius: 20px;
  padding: 20px 40px;
}

.main-end-quiz .next-quiz .next-quiz-title {
  font-weight: 500;
  font-size: 24px;
  color: black;
  margin-bottom: 20px;
  text-align: center;
}

.main-end-quiz .head-next-quiz {
  font-weight: 400;
  font-size: 24px;
  color: #034077;
  margin-top: 20px;
  text-align: center;
}

.main-end-quiz .next-quiz .info-table {
  width: 100%;
  margin-top: 20px;
}

.main-end-quiz .next-quiz .info-table table {
  width: 100%;
  border-collapse: collapse;
}

.main-end-quiz .next-quiz .info-table table tr td {
  padding: 10px;
}

.main-end-quiz .next-quiz .start-next-quiz {
  width: 90%;
  height: 50px;
  background: #034077;
  border-radius: 10px;
  border: none;
  color: white;
  font-weight: 500;
  font-size: 20px;
  cursor: pointer;
  margin-top: 20px;
  margin-inline: auto;
  display: block;
}

.start-next-quiz:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.quizEndPage {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
}

.quizEndPage .instructions {
  font-size: 20px;
  position: fixed;
  z-index: 2;
  width: 100vw;
  height: 100vh;
  background-color: #0000007a;
  backdrop-filter: blur(5px);
  left: 0px;
}

.quizEndPage .instructions .child-instruct {
  background-color: white;
  padding: 20px 50px;
  border-radius: 10px;
  max-width: 85%;
  width: 700px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.quizEndPage .instructions .child-instruct h1 {
  font-size: 1.6rem;
  text-align: center;
}

.quizEndPage .instructions .child-instruct p {
  font-size: 17px;
}

.quizEndPage .instructions .child-instruct .start-quiz-btn {
  width: fit-content;
  padding: 10px 18px;
  background-color: #4598e1;
  border-radius: 10px;
  color: white;
  font-size: 20px;
  border: none;
  margin-inline: auto;
  display: block;
}

@media screen and (max-width: 1200px) {
  .main-end-quiz {
    gap: 20px;
  }

  .main-end-quiz .custom-message pre {
    margin-block: 0px;
  }
  .main-end-quiz .head-next-quiz {
    margin-block: 0px;
  }
}

@media screen and (max-width: 768px) {
  .main-end-quiz .next-quiz {
    width: 70%;
    padding-inline: 20px;
  }
}

@media screen and (max-width: 425px) {
  .main-end-quiz {
    width: 95%;
    margin-inline: auto;
  }
  .main-end-quiz .next-quiz {
    width: 90%;
  }
  .main-end-quiz .next-quiz {
    padding-inline: 20px;
  }
  .main-end-quiz .custom-message {
    font-size: 22px;
  }
  .main-end-quiz .pass-fail {
    font-size: 20px;
  }
  .main-end-quiz .show-score {
    font-size: 18px;
  }
  .main-end-quiz .head-next-quiz {
    font-size: 18px;
  }
  .main-end-quiz .next-quiz .next-quiz-title {
    font-size: 18px;
  }
  .main-end-quiz .next-quiz .start-next-quiz {
    font-size: 18px;
  }
  .quizEndPage .instructions .child-instruct h1 {
    font-size: 18px;
  }
  .quizEndPage .instructions .child-instruct {
    padding: 20px;
  }
  .quizEndPage .instructions .child-instruct .start-quiz-btn {
    font-size: 18px;
  }
  .quizEndPage .instructions .child-instruct p {
    font-size: 15px;
  }
}
