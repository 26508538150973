.upper-main-timeout {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.292);
}

.upper-main-timeout .main-tab-change {
  width: 600px;
  margin-inline: auto;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  margin-top: 15%;
}

.upper-main-timeout .main-tab-change .title {
  font-weight: 600;
  font-size: 22px;
  margin-block: 10px;
}

.upper-main-timeout .main-tab-change .warn {
  font-size: 18px;
  margin-block: 10px;
  line-height: 25px;
}

.upper-main-timeout .main-tab-change .timeout {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.upper-main-timeout .main-tab-change .timeout p {
  color: red;
  font-size: 18px;
  font-weight: 600;
}

.upper-main-timeout .main-tab-change .timeout button {
  font-size: 20px;
  padding-inline: 40px;
  padding-block: 5px;
  background-color: #4598e1;
  height: fit-content;
  border: none;
  color: white;
  cursor: pointer;
  transition: 0.2s;
}

.upper-main-timeout .main-tab-change .timeout button:hover {
  box-shadow: 0px 0px 10px #4598e1;
}

.disable-print {
  margin: 0px;
}

@media print {
  .disable-print {
    display: none !important;
  }
}

@media screen and (max-width: 768px) {
  .upper-main-timeout .main-tab-change {
    width: 95%;
  }
}
