.unselectableHeading {
  pointer-events: none;
}

.dropdown,
input[type="checkbox"] {
  cursor: pointer;
}

.restoremodelinksoutter {
  font-size: 1vw;
  color: #848891;
  font-weight: 500;
  grid-column: 2 / 6;
  grid-row: 1;
}
.restoremodelinksinner {
  grid-column: 1 / 2;
  grid-row: 1;
  text-align: left;
}

.displaymessageatenddiv {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  -webkit-user-modify: read-write-plaintext-only;
}
.endmessagespanele {
  background: #a3d3fb42;
  border: 1px solid #87d0ea;
  padding: 7px;
  border-radius: 7px 7px 0px 0px;
  color: #27577f;
  font-weight: 700;
}

#editor1 {
  margin-top: 2%;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  font-size: 22px;
  border: 1px solid rgba(87, 86, 86, 0.445);
  text-align: center;
  word-wrap: break-word;
  line-height: 26px;
  white-space: break-spaces;
  border-radius: 3px;
  padding: 10px;
  -webkit-user-modify: read-write-plaintext-only;
}

.displaymessageatendstyle {
  margin-top: 5.3px !important;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  font-size: 22px;
  text-align: center;
  word-wrap: break-word;
  padding: 14px 10px !important;
  border: 1px solid #00000040 !important;
  -webkit-user-modify: read-write-plaintext-only;
}

#h4text {
  text-align: left;
  width: 90%;
  margin-left: auto;
  font-size: 16px;
  margin-top: 10px;
}

.resultContainer {
  text-align: center;
  margin-top: 60px;
}

.sidebar-additional #select_title {
  color: #162d49;
  font-size: 1.2vw;
  font-weight: bold;
  padding-left: 4%;
  padding-bottom: 20px;
  border-bottom: 1px solid #dee2e7;
}

.sidebar-additional .dropdown {
  display: flex;
  align-items: center;
  margin-left: 0px;
  padding-left: 3%;
  width: 100%;
  border-radius: 0px;
  margin-top: 0px;
  padding-block: 2%;
}

.sidebar-additional .dropdown#tr2 {
  margin-top: 15px;
}

.sidebar-additional .dropdown .restoremodelinksinner input {
  margin: 0%;
}

.sidebar-additional .dropdown:hover {
  background-color: #cde8ff;
  color: #3e4e5f;
}
