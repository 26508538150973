.upper-main-sidebar {
  height: 100vh;
  border-right: 1px solid rgba(128, 128, 128, 0.178);
  background-color: #f0f8ff;
  height: 100vh;
}

.main-sidebar {
  width: 400px;
  min-width: 400px;
  border-right: 1px solid rgba(128, 128, 128, 0.178);
  background-color: #f0f8ff;
  height: 100vh;
  display: grid;
  /* height: auto; */
  /* grid-template-rows: 1fr 1fr auto; */
  /* font-family: "Inter", sans-serif; */
  display: flex;
  flex-direction: column;
  height: 90vh;
}

.main-sidebar .quiz-title,
.main-sidebar .add-quiz-btn {
  padding-inline: 20px;
}

.main-sidebar .quiz-title {
  border-bottom: 1px solid #dee2e7;
  display: flex;
  font-size: 24px;
  font-weight: bold;
  color: #072642;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  align-items: center;
  min-height: 50px;
}
.main-sidebar .quiz-title span {
  margin-block: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.main-sidebar .add-quiz-btn {
  display: flex;
  height: fit-content;
}

.main-sidebar .add-quiz-btn button {
  background-color: #002653;
  color: white;
  font-size: 18px;
  padding-inline: 28px;
  padding-block: 5px;
  border-radius: 20px;
  border: none;
  text-align: center;
  margin-block: 14px;
  margin-inline: auto;
  width: fit-content;
  outline: none;
  transition: 0.2s;
  padding-block: 10px;
  padding-inline: 45px;
}

.main-sidebar .add-quiz-btn button:hover {
  box-shadow: 0 0 10px #3e4e5f;
}

.questions-container {
  overflow-y: auto;
  /* border-top: 1px solid #dcdcdc; */
}

.questions-container::-webkit-scrollbar {
  width: 5px;
}

.questions-container::-webkit-scrollbar-track {
  background-color: white;
}

.questions-container::-webkit-scrollbar-thumb {
  background-color: #002653;
}

.questions-container ol {
  flex-direction: column;
  gap: 20px;
  padding-left: 0px;
  width: 100%;
  margin-top: 0px;
  height: fit-content;
  /* height: 50 */
}

.questions-container ol li {
  font-size: 18px;
  list-style-position: inside;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-inline: 10px;
  padding-block: 10px;
  transition: 0.2s;
  cursor: pointer;
  color: #848891;
  list-style: none;
}
.questions-container .questions-container {
  overflow: hidden;
}

.questions-container ol li:hover {
  background-color: #cde8ff;
  color: #3e4e5f;
}

.questions-container ol li.active {
  color: #162d49;
  font-weight: bold;
}

.questions-container .question-drag-icon {
  visibility: hidden;
  font-weight: bold;
  font-size: 18px;
  margin-right: 5px;
}

.questions-container ol li .delete-question-icon {
  float: right;
  visibility: hidden;
  transition: 0.2s;
}

.questions-container ol li:hover > .delete-question-icon {
  visibility: visible;
}

.questions-container ol li:hover > .question-drag-icon {
  visibility: visible;
}

.main-edit-quiz-sidebar {
  padding: 10px;
}

.main-edit-quiz-sidebar .title {
  font-size: 20px;
  font-weight: 700;
  padding-left: 10px;
}

.main-edit-quiz-sidebar .info {
  font-weight: 600;
  padding: 15px;
  font-size: 18px;
  padding-block: 10px;
  /* border-bottom: 1px solid #dcdcdc; */
}

.main-edit-quiz-sidebar .info .optional {
  font-weight: normal;
  font-size: 16px;
}

.main-edit-quiz-sidebar .info-list {
  padding-inline-start: 0px;
  list-style-type: none;
}

.main-edit-quiz-sidebar .btn-next {
  background-color: #002653;
  color: white;
  font-size: 18px;
  border-radius: 20px;
  border: none;
  text-align: center;
  margin-block: 30px;
  margin-inline: auto;
  width: fit-content;
  outline: none;
  transition: 0.2s;
  padding-block: 10px;
  padding-inline: 45px;
  display: flex;
}

.main-sidebar-no-questions {
  padding: 10px;
}

.main-sidebar-no-questions p {
  margin: 1em;
}
.main-sidebar-no-questions .title {
  font-size: 20px;
  font-weight: 700;
}

.main-sidebar-no-questions .title-info {
  font-size: 16px;
  font-weight: 500;
}

.main-sidebar-no-questions .question-pop .main-add-q-popup {
  position: static;
  width: 100%;
  box-shadow: none;
  background-color: transparent;
}

.main-sidebar-no-questions .question-pop .main-add-q-popup::before {
  display: none;
}

.main-sidebar-no-questions
  .question-pop
  .main-add-q-popup
  .question-list-header {
  display: none;
}

.question-pop .main-add-q-popup .last-selected-question {
  font-size: 16px;
  padding-inline: 20px;
  cursor: pointer;
  padding-left: 0px;
  border-radius: 20px;
  background: #b4dcff80;
  border: 1px solid transparent;
  background: #052949;
  color: white;
  width: 350px;
  margin-inline: auto;
  font-size: 18px;
  transition: 0.2s;
  font-weight: normal;
}

.main-sidebar-no-questions .question-pop .main-add-q-popup .question-list {
  padding: 0px;
}

.sidebar-main .quiz-title {
  display: block;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  list-style: none;
  width: 100%;
  color: #162d49;
  font-weight: bold;
  font-size: 1.2vw;
  min-height: fit-content;
  padding-top: 20px;
  padding-bottom: 15px;
  border-radius: 0px;
  border-bottom: 1px solid #dee2e7;
  cursor: default;
}

.sidebar-main .quiz-title:hover {
  color: #005284;
  background-color: #f0f8ff;
}

@media only screen and (min-width: 1950px) and (max-width: 1300px) {
  .main-sidebar {
    width: 350px;
    min-width: 350px;
  }
}

.nav_fixed {
  position: fixed;
}

#settings_sidebar {
  padding-top: 5%;
}

.quizsettings {
  padding-top: 80px;
}

@media screen and (max-width: 1500px) {
  .main-sidebar {
    width: 300px;
    min-width: 300px;
  }

  .question-pop .main-add-q-popup .last-selected-question {
    max-width: 100%;
  }
  .main-sidebar-no-questions .main-add-q-popup .list-item {
    max-width: 100%;
  }
}
