.question-editing-container .question-title {
  font-size: 27px;
  border: 1px solid black;
  min-width: 70%;
  max-width: 70%;
  padding: 10px;
  margin-inline: auto;
  width: fit-content;
  height: auto;
  word-break: break-all;
}

.main-yes-no {
  border: 1px solid;
  margin-top: 2%;
  margin-left: auto;
  margin-right: auto;
  width: 95%;
  border-radius: 6px;
  height: auto;
  padding-block: 20px;
  padding-inline: 30px;
  padding-bottom: 50px;
}
.main-yes-no .d-title {
  font-size: 17px;
  font-weight: bold;
}
.main-yes-no table {
  width: 90%;
  border-collapse: collapse;
  /* border-spacing: 20px; */
}
.main-yes-no table tr {
  background-color: #fff3e6;
  border-radius: 6px;
}

.main-yes-no table td:first-child {
  width: 34px;
  padding-block: 30px;
  padding-inline: 15px;
}

.main-yes-no table input {
  width: 21px;
  height: 35px;
  vertical-align: middle;
}
