.containermain {
  height: auto;
  width: 75%;
  float: left;
  margin-left: 21%;
  padding-bottom: 2%;
  padding-left: 4%;
}

.main_heading {
  color: #005284;
  font-weight: bold;
  font-size: 1.8rem;
  text-align: center;
}

p,
h4,
h3 {
  margin: 0px;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: #1c1c1c !important;
}

.rs-picker-toggle-wrapper {
  z-index: 0;
}

.sidebar-main {
  margin: 0;
  padding-top: 2%;
  width: 22%;
  background-color: #f0f8ff;
  height: 100%;
  float: left;
  position: fixed;
  border-right: 1px solid #dee2e7;
}

.sidebar-main li {
  list-style: none;
  min-height: 50px;
  align-items: center;
  width: 100%;
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
  height: auto;
  padding-left: 4%;
  cursor: pointer;
  transition: 0.2s;
  font-size: 18px;
}

.sidebar-links {
  padding-left: 0%;
  margin-top: 0px;
}

.sidebar-main li a {
  text-decoration: none;
  list-style: none;
  width: 100%;
  color: #848891;
  font-size: 1.2vw;
  height: auto;
  padding: 2%;
  border-radius: 10px;
}

.highlighted-link {
  color: #162d49;
  background-color: #cde8ff;
}

.highlighted-link:hover {
  background-color: #cde8ff;
  color: #3e4e5f;
}
.sidebar-main li:hover {
  background-color: #0269aa4e;
}
.highlighted-link a {
  color: #072642 !important;
  font-weight: bold;
}

input[type="number"] {
  width: 70px;
}
.timeavailabalityhead {
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding-bottom: 5px;
  font-weight: 400;
}

input[type="number"] {
  width: 70px;
}

.timeavailabalityhead {
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding-bottom: 5px;
  font-weight: 400;
  position: relative;
}

.timeavailabalityhead #pickadateandtime {
  background-image: url(./../../../public/images/calendar-1.svg);
  background-size: 18px;
  background-repeat: no-repeat;
  background-position: 99%;
  border-radius: 4px;
  cursor: pointer;
  width: 80%;
}

.timeavailabalityhead .rdrDateRangePickerWrapper {
  /* position: absolute; */
  /* z-index: 1; */
  /* box-shadow: 0px 0px 11px 0px rgb(0 0 0 / 50%); */
  /* top: 65px; */
}

.timeavailabalityhead .rdrMonths.rdrMonthsHorizontal {
  gap: 10px;
}

.timeavailabalityhead .rdrMonth {
  border-bottom: 1px solid #d6d6d6;
  padding-bottom: 10px;
  padding-inline: 0px;
}

.timeavailabalityhead .date-time-picker {
  position: absolute;
  z-index: 1;
  box-shadow: 0px 0px 11px 0px rgb(0 0 0 / 50%);
  background-color: #fff;
  bottom: 40px;
}

.timeavailabalityhead .date-time-picker .time-picker {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
  padding: 0 0.833em 0.666em 0.833em;
  justify-items: center;
  margin-top: 15px;
}

.timeavailabalityhead .date-time-picker .start-time,
.timeavailabalityhead .date-time-picker .end-time {
  width: 170px;
}

.timeavailabalityhead .time-picker .start-time,
.timeavailabalityhead .time-picker .end-time {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.timeavailabalityhead .time-picker .heading {
  font-weight: 500;
  text-align: center;
}

.timeavailabalityhead .time-picker .head {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
}

.timeavailabalityhead .time-picker .body {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
}

.timeavailabalityhead .time-picker .body select {
  outline: none;
  border: none;
  background-color: #f2f2f2;
}

.timeavailabalityhead .date-time-picker .final-date-time {
  border-top: 1px solid #d6d6d6;
  padding: 0.666em 0.833em 0.666em 0.833em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.timeavailabalityhead .date-time-picker .final-date-time .apply-btn {
  background-color: #005284;
  color: #fff;
  padding: 0.2em 0.5em;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  margin-left: 20px;
  border: 1px solid #005284;
}

.timeavailabalityhead .date-time-picker .final-date-time .cancel-btn {
  background-color: #fff;
  color: #005284;
  padding: 0.2em 0.5em;
  border-radius: 4px;
  border: 1px solid #005284;
  cursor: pointer;
}

.timeavailabalityhead .rdrDefinedRangesWrapper {
  display: none;
}

.timeavailabalityhead input {
  width: 70%;
  border-radius: 4px;
}

.settingsheadings {
  width: 100%;
  height: auto;
  background-color: rgb(156 208 252 / 48%);
  font-size: 20px;
  font-weight: bold;
  padding: 8px;
  margin: 18px 0px;
  border-top: 1px solid #8fd1ffb8;
  border-bottom: 1px solid #8fd1ffb8;
}

.settingsrow {
  width: 1300px;
  vertical-align: middle;
  padding: 5px;
  margin-top: 8px;
}

.settingsrowselement p,
.securitysettingsoptions p,
.scoringsettingsoptions p {
  float: none;
  font-size: 18px;
  font-weight: bold;
  margin: 0%;
}

.settingsrowselement {
  min-width: 45%;
  display: inline-grid;
  padding: 5px;
  font-size: 16px;
}

.settingsrow select {
  height: 26px;
  border-radius: 4px;
  font-size: 16px;
}
#selectrandomorder:first-letter {
  text-transform: capitalize;
}

/* popup */
.popupoverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.43);
  backdrop-filter: blur(1px);
}
.popup {
  background: #fff;
  padding: 20px;
  top: 30%;
  left: 30%;
  border-radius: 8px;
  z-index: 10;
  position: fixed;
  box-shadow: 0px 0px 11px 0px rgb(0 0 0 / 50%);
  border-radius: 13px;
  transition: transform 0.3s;
  animation-name: classyFadeIn;
  -webkit-animation-name: classyFadeIn;
  animation-duration: 0.8s;
  -webkit-animation-duration: 0.8s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
}

.popup-container.open {
  opacity: 1;
  pointer-events: auto;
}
.customizedheadingsection p {
  font-weight: 500;
  padding-bottom: 10px;
  font-size: 16px;
}
.customizedheadingsection textarea {
  font-weight: 400;
  font-size: 15px;
  width: 100%;
  padding-left: 10px;
  border-radius: 4px;
}
.customizedheadingsection {
  width: 800px;
}
.savebtnquiz {
  background-color: #01bbde;
  color: white;
  font-size: 17px;
  padding: 2px 35px;
  border: none;
  border-radius: 10px;
}
#customizemessage {
  background-color: #2285ffbf;
  color: white;
  font-size: 15px;
  padding: 1px 15px;
  border: none;
  border-radius: 10px;
}

.ques_displayed {
  width: 100%;
  display: inline-flex;
  align-items: center;
  gap: 10px;
}
.close {
  position: absolute;
  right: 4px;
  top: 4px;
  width: 26px;
  border: 2px solid gray;
  border-radius: 50%;
  height: 26px;
  opacity: 0.3;
  cursor: pointer;
}
.close:hover {
  opacity: 1;
}
.close:before,
.close:after {
  position: absolute;
  left: 10.5px;
  content: " ";
  top: 2px;
  height: 19px;
  width: 2px;
  background-color: #333;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}

.settingsrow input[type="checkbox"] {
  -ms-transform: scale(2);
  -moz-transform: scale(2);
  -webkit-transform: scale(2);
  -o-transform: scale(2);
  transform: scale(1.1);
  padding: 10px;
  float: none;
}

/* securitysettings */
.securitysettingsoptions {
  min-width: 45%;
  padding: 5px;
  font-size: 16px;
  width: 50%;
  vertical-align: middle;
  padding: 5px;
  margin-top: 8px;
}

.securitysettingsoptions input {
  float: right;
}

.assignpointstable input[type="number"]::-webkit-outer-spin-button,
.assignpointstable input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: initial;
}

.quizsettings input[type="number"] {
  text-align: center;
}

/* Scoring options */
.assignpointstable {
  width: 95%;
  border-collapse: collapse;
}
.assignpointstable th {
  border-top: 3px solid;
  border-bottom: 3px solid;
  background-color: rgb(247, 243, 243);
  padding-top: 15px;
  padding-left: 10px;
  vertical-align: middle;
  border-right: 2px solid rgb(224, 222, 222);
  padding-bottom: 15px;
  font-size: 16px;
  z-index: -1;
}
.assignpointstable td {
  border-bottom: 1px solid;
  height: 39px;
  padding-left: 10px;
  padding-right: 10px;
  vertical-align: top;
  padding-block: 8px;
}

.assignpointstable .text-center {
  text-align: center;
}

.scoringsettingsoptions {
  width: 100%;
  padding: 5px;
  font-size: 15px;
  display: flex;
  margin-top: 10px;
}
.scoringsettingsoptions > div {
  width: 50%;
}
#scoring_options input {
  float: left !important;
}

#scoring_options #totalpoints::-webkit-inner-spin-button,
#scoring_options #totalpoints::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.scoringsettingsoptions {
  min-width: 45%;
  padding: 5px;
  font-size: 16px;
  width: 100%;
  vertical-align: middle;
  padding: 5px;
  margin-top: 8px;
}

.scoringsettingsoptions input {
  float: right;
}

/* quiz tracking */

.quiztrackingoptions {
  width: 42%;
  margin-left: 9px;
  font-size: 17px;
}

.quiztrackingoptions input {
  float: right;
}

.quizTrackingPasswordField {
  display: inline-block;
  padding: 5px;
  border-radius: 7px;
  border: 1px solid #9b9b9b;
}

#askquestionlink {
  color: #fff;
  border-radius: 30px;
  padding: 10px 14px;
  width: 30%;
  background: #3a7497;
  border: none;
  margin-top: 20px;
  font-size: 18px;
}

.additionalquestions {
  display: flex;
  flex-direction: column;
  width: 80% !important;
  margin-top: 2%;
}

/* Lead Integration */

.leadgenerationtable td {
  border-bottom: 1px solid;
  height: 65px;
  font-size: 16px;
  padding: 10px;
}

.leadgenerationtable {
  width: 100%;
}

.leadgenerationtable th {
  font-size: 17px;
  font-weight: normal;
  text-align: left;
}

input[type="radio"] {
  -ms-transform: scale(2); /* IE */
  -moz-transform: scale(2); /* FF */
  -webkit-transform: scale(2); /* Safari and Chrome */
  -o-transform: scale(2); /* Opera */
  transform: scale(2);
  padding: 10px;
}
.rs-calendar-header {
  display: flex;
  align-items: center;
}
.rs-calendar-header-title,
.rs-calendar-header-meridian {
  font-size: 15px !important ;
  color: #005284;
  font-weight: 500;
}
.rs-calendar-header-forward,
.rs-calendar-header-backward {
  font-size: 20px;
  font-weight: 500;
  color: #005284;
}

.month-label {
  position: absolute;
  top: 8px;
  font-size: 14px;
  font-weight: 600;
}
.month-label {
  display: none;
}
.month-label:first-child {
  display: block !important;
}
.Time-label {
  position: absolute;
  top: 30px;
  right: 50px;
  font-weight: 500;
  font-size: 13px;
}
.Date-label {
  position: absolute;
  top: 30px;
  left: 55px;
  font-size: 13px;
  font-weight: 500;
}
.rs-calendar {
  padding-top: 50px;
  font-family: "Inter", sans-serif;
}
.rs-calendar-btn-close {
  height: 50px;
}
.rs-calendar-btn-close svg {
  width: 50px;
}
.rs-calendar-header-backward svg,
.rs-calendar-header-forward svg {
  width: 20px;
}
.rs-calendar-table-header-cell {
  font-size: 14px;
  font-weight: 500;
}
.rs-picker-daterange-header {
  padding-left: 100px;
  font-weight: 500;
  font-size: 15px;
}
.Setto_label {
  position: absolute;
  top: 6px;
  color: #005284;
  left: 11px;
  font-weight: 500;
}

.rs-picker-toggle {
  border-color: #1c1c1c !important;
}
.rs-stack-item span {
  color: #1c1c1c !important;
}

.rs-calendar-header {
  padding-top: 34px;
}

.rs-picker-daterange-content {
  padding-top: 20px;
}

.rs-calendar-btn-close {
  height: 50px !important;
}

.settingsrow input[type="radio"] {
  margin-left: 5px;
}

.quizsettings .next-quiz-table {
  width: 80%;
  margin-top: 10px;
  border-collapse: collapse;
}

.quizsettings .next-quiz-table thead {
  border: 1px solid #c4c4c4;
  padding: 10px;
  font-size: 16px;
  padding-block: 10px;
}

.quizsettings .next-quiz-table thead th {
  /* border: 1px solid #c4c4c4; */
  padding: 10px;
  font-size: 16px;
  padding-block: 10px;
}

.quizsettings .next-quiz-table tbody tr {
  border: 1px solid #c4c4c4;
  padding: 10px;
  font-size: 16px;
  padding-block: 10px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.quizsettings .next-quiz-table tbody tr:hover {
  background-color: #f2f2f2;
}

.quizsettings .next-quiz-table tbody td {
  /* border: 1px solid #c4c4c4; */
  padding: 10px;
  font-size: 16px;
  padding-block: 10px;
}

.quizsettings .next-quiz-table input[type="radio"] {
  transform: scale(1.5);
  cursor: pointer;
}

.quizsettings .next-quiz-table .text-center {
  text-align: center;
}

.quizsettings .next-quiz-table tbody label {
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: block;
}
