html {
  scroll-behavior: smooth;
}

.container.Main_page_quotes {
  display: flex;
  margin: auto;
  width: 100%;
  background: rgb(0, 122, 255);
  background: linear-gradient(
    107deg,
    rgba(0, 122, 255, 1) 0%,
    rgba(100, 111, 255, 1) 60.65%,
    rgb(90, 57, 253) 60.65%
  );
  flex-direction: row;
  max-width: 100%;
  padding-bottom: 85px;
  height: 90vh;
  align-items: center;
  margin-bottom: 86px;
  clip-path: polygon(0% 0, 100% 0, 100% 100%, 0% 100%, 50%);
}
.mainPageContainerInside {
  width: 35%;
  text-align: left;
}

#create_quiz_btn {
  height: 4rem;
  width: 25rem;
  margin-top: 3rem;
  padding-left: 1rem;
  text-align: center;
  font-size: 23px;
  border: 3px solid rgb(255 255 255);
  border-radius: 49px;
  background: rgb(255, 255, 255);
  color: #0078d2;
}
#create_quiz_btn:hover {
  background: #eb5a2d;
  border-color: #eb5a2d;
  color: white;
}

div > .text-leftmain {
  font-size: clamp(2.125rem, 2rem + 1.25vw, 5.375rem);
  margin-bottom: 2rem;
  color: #ffffff;
  font-weight: 600;
  text-align: left;
}

div > .text-left {
  font-size: 25px;
  font-weight: 400;
  width: 100%;
  text-align: left;
  color: #f5f5f5;
}

.takeTourBtn {
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 23px;
}

.Text-center {
  text-align: center;
  padding-bottom: 1%;
  font-size: 36px;
  margin-top: 0px;
  color: #000000;
}

.Requirement-details {
  max-width: 1650px;
  margin: 30px auto;
  padding: 0 20px;
  width: 100%;
  display: grid;
  grid-auto-rows: 90px;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-gap: 1.5em;
  grid-row-gap: 2.5em;
}

.article_list {
  border-radius: 10px;
  font-size: 18px;
  display: flex;
  gap: 25px;
  padding: 20px;
  color: #000000;
  align-items: center;
}

#create_quiz {
  margin-top: 50px;
  display: flex;
  border-radius: 28px;
  background-color: #1897ff;
  border: 1px solid transparent;
  padding: 4px 4px;
  padding-top: 3px;
  width: 13rem;
  font-size: 20px;
  justify-content: center;
  align-items: center;
}

.article_list:nth-child(odd) {
  background-color: #ffffff;
  border: 2px solid rgba(150, 191, 252, 0.653);
}

.article_list:nth-child(even) {
  background-color: #ffffff;
  border: 2px solid rgba(150, 191, 252, 0.653);
}

#home_h6 {
  margin-top: 0px;
  color: #000000;
  font-size: 25px;
  line-height: 32px;
  font-weight: 400;
  text-align: left;
  width: 700px;
}

.securityIntegrationRow {
  padding: 50px;
  flex-direction: row-reverse;
  display: flex;
}

.videoResponseContainer {
  max-width: 1700px;
  margin: auto;
  background-color: #eaf1f4;
  border-radius: 5px;
}

#feature_list_home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 80px;
}

#feature_list_home div {
  display: flex;
  width: 77%;
  justify-content: flex-start;
}

.cards-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2% 2% 3% 2%;
  background-color: #ebeff3;
  padding-top: 80px;
  padding-bottom: 65px;
}

.cards__container {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1600px;
  overflow: hidden;
  padding: 45px 1px;
  height: auto;
}

.box {
  width: 51rem;
  height: 30rem;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: 3px 4px 50px rgba(0, 0, 0, 0.25);
  border-radius: 35px;
}
.home_carousel {
  display: flex;
  width: 100%;
  gap: 10%;
  justify-content: center;
  bottom: 470px;
  position: relative;
}
.box:nth-child(2n) {
  transform: scale(0.75);
  background: rgba(255, 255, 255, 0.85);
}

.box:nth-child(2) {
  left: 0%;
}

.box:nth-child(4) {
  left: 0%;
}

.box:nth-child(4n + 1) {
  transform: scale(0.75);
}

.box:nth-child(1) {
  left: -15%;
}

.box:nth-child(5) {
  left: 15%;
}

.card__text-content {
  text-align: center;
  width: 75%;
}

.cardTextContentInside {
  height: 70px;
  border-radius: 45% 45% 0% 0%;
  position: relative;
  bottom: 45px;
  background-color: #ffffff;
}

.card__title {
  padding: 1rem;
}

.box--hide {
  display: none;
}

.move-out-from-left {
  animation: moveOutLeft 0.5s ease-in-out;
}

.move-out-from-right {
  animation: moveOutRight 0.5s ease-in-out;
}

.move-to-position5-from-left {
  animation: moveToP5Left 0.5s ease-in-out;
}

.move-to-position4-from-left {
  animation: moveToP4Left 0.5s ease-in-out;
}

.move-to-position3-from-left {
  animation: moveToP3Left 0.5s ease-in-out;
}

.move-to-position2-from-left {
  animation: moveToP2Left 0.5s ease-in-out;
}

.move-to-position1-from-left {
  animation: moveToP1Left 0.5s ease-in-out;
}

.move-to-position5-from-right {
  animation: moveToP5Right 0.5s ease-in-out;
}
.move-to-position4-from-right {
  animation: moveToP4Right 0.5s ease-in-out;
}
.move-to-position3-from-right {
  animation: moveToP3Right 0.5s ease-in-out;
}
.move-to-position2-from-right {
  animation: moveToP2Right 0.5s ease-in-out;
}
.move-to-position1-from-right {
  animation: moveToP1Right 0.5s ease-in-out;
}

@keyframes moveOutLeft {
  0% {
    transform: scale(0.75) translateX(0%);
    opacity: 1;
  }
  50% {
    transform: scale(0.5) translateX(-150%);
    opacity: 0.5;
  }
  100% {
    transform: scale(0.25) translateX(0%);
    opacity: 0;
  }
}

@keyframes moveOutRight {
  0% {
    transform: scale(0.75) translateX(0%);
    opacity: 1;
  }
  50% {
    transform: scale(0.5) translateX(150%);
    opacity: 0.5;
  }
  100% {
    transform: scale(0.25) translateX(0%);
    opacity: 0;
  }
}

@keyframes moveToP5Left {
  from {
    transform: scale(0.75) translateX(100%);
  }
  to {
    transform: scale(0.75) translateX(0);
  }
}

@keyframes moveToP4Left {
  from {
    transform: scale(0.75) translateX(100%);
  }
  to {
    transform: scale(0.75) translateX(0);
  }
}

@keyframes moveToP3Left {
  from {
    transform: scale(0.75) translateX(100%);
  }
  to {
    transform: scale(1) translateX(0);
  }
}

@keyframes moveToP2Left {
  from {
    transform: scale(1) translateX(100%);
  }
  to {
    transform: scale(0.75) translateX(0);
  }
}

@keyframes moveToP1Left {
  from {
    transform: scale(0.75) translateX(100%);
  }
  to {
    transform: scale(0.75) translateX(0);
  }
}

@keyframes moveToP1Right {
  from {
    transform: scale(0.75) translateX(-100%);
  }
  to {
    transform: scale(0.75) translateX(0);
  }
}

@keyframes moveToP2Right {
  from {
    transform: scale(0.75) translateX(-100%);
  }
  to {
    transform: scale(0.75) translateX(0);
  }
}

@keyframes moveToP3Right {
  from {
    transform: scale(0.75) translateX(-100%);
  }
  to {
    transform: scale(1) translateX(0);
  }
}

@keyframes moveToP4Right {
  from {
    transform: scale(1) translateX(-100%);
  }
  to {
    transform: scale(0.75) translateX(0);
  }
}

@keyframes moveToP5Right {
  from {
    transform: scale(0.75) translateX(-100%);
  }
  to {
    transform: scale(0.75) translateX(0);
  }
}

@media only screen and (min-width: 1224px) {
  .box:nth-child(2) {
    left: -1%;
  }
  .box:nth-child(4) {
    left: 1%;
  }
}

@media only screen and (min-width: 1824px) {
  .box:nth-child(2) {
    left: -4%;
  }
  .box:nth-child(4) {
    left: 4%;
  }
}
.stop-scrolling {
  height: 100%;
  overflow: hidden;
}
input#upload,
input#checkbox {
  margin: 4px 9px 0px 0px;
  height: 18px;
  width: 18px;
}

.button.leftbtn,
.button.rightbtn {
  background-color: #052949;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  position: relative;
  z-index: 1;
  opacity: 0.3;
}

.button.leftbtn:hover,
.button.rightbtn:hover {
  opacity: 1;
}

.button.leftbtn:hover,
.button.rightbtn:hover {
  opacity: 1;
}

.button.rightbtn {
  right: -40%;
}

.button.leftbtn {
  left: -40%;
}

.button svg {
  width: 48px;
  height: 51px;
  position: relative;
  fill: white;
  opacity: 0.8;
  cursor: pointer;
}

.button svg:active {
  transform: scale(0.9);
  opacity: 1;
  transition-property: transform, opacity;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}

.button img {
  height: 17px;
  margin: 17px 1px 0px 15px;
}

.quizmainslider {
  overflow-y: hidden;
  overflow-x: hidden;
}

.button {
  margin-left: 0 3%;
  width: 2rem;
  cursor: pointer;
}

.button--inactive {
  opacity: 0.2;
}

.button img {
  width: 17px;
}

/* Good code above */

.middle_section img {
  margin-top: 1.5rem;
  margin-left: 0;
}

.field-validation-error {
  color: #b94a48;
}

.field-validation-valid {
  display: none;
}

input.input-validation-error {
  border: 1px solid #b94a48;
}

.input-validation-error {
  border: 0 none;
}

.validation-summary-errors {
  margin-left: 5%;
  color: #b94a48;
}

.validation-summary-valid {
  display: none;
}

progress {
  height: 3rem;
  width: 25rem;
}

#topheader .navbar-nav li > a {
  color: #333;
  transition:
    background-color 0.2s,
    color 0.2s;
  width: 100%;
  font-size: 1.2vw;
}

a > img {
  height: auto;
}

#Our_Features {
  padding-top: 15px;
}

.featuresSubheading {
  font-size: 25px;
  margin-top: 0;
  color: #000000;
  font-size: 25px;
  font-weight: 400;
  text-align: center;
}

.templateExampleSubText {
  text-align: left;
  padding-left: 6%;
}

.mcqcontainer {
  width: 467px;
  height: auto;
  border-radius: 20px;
  margin-bottom: 9%;
}

.mcqcontainer ul li {
  display: flex;
  text-align: center;
  align-items: baseline;
  margin-left: 10px;
}

.mcqcontainer #mrq li input {
  margin-top: -7px;
}

.mcqcontainer #mrq li {
  display: flex;
  align-items: center;
}

.mcqcontainer ul li input[type="radio"] {
  height: 12px;
  width: 23px;
  vertical-align: middle;
  margin-right: 15px;
}

.mcqcontainer ul li input[type="checkbox"] {
  height: 23px;
  width: 23px;
  margin-right: 13px;
}

.mcqcontainer ul {
  margin-left: -11px;
}

.mcqcontainer h3 {
  color: #5881b2;
  font-weight: 600;
  font-size: 28px;
}

.mcqcontainer p {
  text-align: center;
  color: #052949;
  font-weight: 400;
  font-size: 18px;
  padding-top: 5px;
}

.Signuptext-center {
  font-weight: bold;
  font-size: 15px;
  margin-top: 1rem;
  margin-bottom: 0rem;
}

.Signuptext-center > a:hover {
  color: #428bca;
}

.btnquiz {
  margin-right: 28px;
  margin-top: 9px;
  font-size: 1.2vw;
  background-color: #54b8e9;
  color: white;
  border-radius: 4px;
  border: none;
}

.EssayQuestionTemplate {
  font-size: 20;
  padding: "3%";
  resize: "none";
  height: 173px;
  width: 409px;
  margin-top: "2%";
}

.mcqcontainer pre {
  color: #5881b2;
  font-weight: 600;
  font-size: 18px;
  text-align: left;
  font-family: "Inter", sans-serif;
}

@media screen and (max-width: 1440px) {
  .reportSidebar {
    line-height: 1;
  }

  .videoResponseImage {
    width: -webkit-fill-available;
  }

  .article_list {
    margin-left: unset;
    margin-right: unset;
  }

  .imgRequirementDetails {
    margin-left: 7%;
  }

  .pricing-container {
    width: 85%;
  }

  .button.rightbtn {
    right: -33%;
  }

  .button.leftbtn {
    left: -33%;
  }

  .chooseYourPlanText {
    margin-top: 54px;
  }

  #Hero_img {
    width: 80%;
  }

  #feature_list_home div {
    margin-right: 16%;
  }
}

@media screen and (max-width: 1250px) {
  #topheader .navbar-nav li > a {
    padding: 27px 5px 0px 4px;
  }

  .pricing-container {
    width: 50%;
    grid-template-columns: 1fr;
    margin-top: 5.8rem;
    gap: 5rem;
  }

  .container-2 {
    margin-top: initial;
    margin-bottom: initial;
  }

  .container-2 ul li {
    padding: 1.06rem;
  }

  .container-2 h2 {
    padding-top: initial;
  }

  .container-2 p {
    margin-top: initial;
  }
}

@media screen and (max-width: 1280px) {
  .videoResponseImage {
    width: -webkit-fill-available;
  }

  .box {
    width: 42rem;
  }

  .EssayQuestionTemplate {
    width: 351px;
  }

  .pricing-container {
    width: 98%;
  }

  #Hero_img {
    width: 77%;
  }

  #home_h6 {
    width: unset;
  }

  #feature_list_home div {
    margin-right: 23%;
  }
}

@media screen and (max-width: 768px) {
  .btnquiz {
    margin-right: 15px;
    margin-top: 18px;
  }

  .pricing-container {
    width: 80%;
  }
}

.navline {
  height: 46px;
  width: 1px;
  background: white;
}

.emailcard {
  width: 750px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  height: auto;
  border-radius: 7px;
  text-align: center;
  background-color: rgb(247, 243, 243);
  padding: 10px;
}

.emailcard > div {
  border: 2px solid;
  margin-top: 3%;
  width: 46%;
  margin-left: auto;
  margin-right: auto;
  height: 25px;
  border-radius: 14px;
}

.dropbtn {
  background-color: #04aa6d;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
  padding-top: 12px;
}

.dropdown1 {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border: 1px solid;
  border-radius: 6px;
  margin-left: -30%;
}

#make_a_quiz_free {
  height: 4rem;
  width: 23rem;
  margin-top: 3rem;
  margin-right: 1.5rem;
  text-align: center;
  font-size: 18px;
  border-radius: 5px;
  background: rgb(149, 9, 97);
  background: linear-gradient(
    90deg,
    rgba(149, 9, 97, 1) 0%,
    rgba(9, 9, 121, 1) 81%
  );
  color: white;
}

.middle_section img {
  margin-top: 1.5rem;
  margin-left: 0;
}

.field-validation-error {
  color: #b94a48;
}

.field-validation-valid {
  display: none;
}

input.input-validation-error {
  border: 1px solid #b94a48;
}

.input-validation-error {
  border: 0 none;
}

.validation-summary-errors {
  margin-left: 5%;
  color: #b94a48;
}

.validation-summary-valid {
  display: none;
}

progress {
  height: 3rem;
  width: 25rem;
}

#topheader .navbar-nav li > a {
  color: #333;
  transition:
    background-color 0.2s,
    color 0.2s;
  width: 100%;
  font-size: 1.2vw;
  padding: 29px 15px 3px 15px;
}

#topheader .navbar-nav li.active1 {
  background: linear-gradient(
      90deg,
      rgba(102, 136, 181, 1) 0%,
      rgba(5, 41, 73, 1) 51%,
      rgba(102, 136, 181, 1) 100%
    )
    bottom / 45% 4px;
  background-repeat: no-repeat;
}

nav {
  background-color: #a5d4ff;
  box-shadow: 0px 9px 17px rgba(0, 0, 0, 0.25);
}

.navbar-header {
  background: #052949;
  padding: 0.8%;
}

.navbar-collapse .navbar-nav.navbar-right:last-child {
  padding: 0.8%;
}

a > img {
  height: auto;
}

.pagination > .active > a {
  color: white;
}

span.nav-link.loginnav,
.signupnav {
  border: 1.4px solid #052949;
  border-radius: 12px;
  height: 54px;
  padding: 10px 20px 10px 20px;
  color: #052949;
}

span.nav-link.signupnav {
  color: #ffffff;
  font-weight: 600;
  background: #052949;
  border: 2px solid #052949;
}
.Signuptext-center {
  font-weight: bold;
  font-size: 15px;
  margin-top: 1rem;
  margin-bottom: 0rem;
}

.Signuptext-center > a:hover {
  color: #428bca;
}

.btnquiz {
  margin-right: 28px;
  margin-top: 9px;
  font-size: 1vw;
  background-color: #052949;
  color: white;
  border-radius: 4px;
  padding: 2px 15px;
  border: none;
}

@media screen and (max-width: 768px) {
  .btnquiz {
    margin-right: 15px;
    margin-top: 18px;
  }
}

.navline {
  height: 46px;
  width: 1px;
  background: white;
}

.emailcard {
  width: 750px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  height: auto;
  border-radius: 7px;
  text-align: center;
  background-color: rgb(247, 243, 243);
  padding: 10px;
}

.emailcard > div {
  border: 2px solid;
  margin-top: 3%;
  width: 46%;
  margin-left: auto;
  margin-right: auto;
  height: 25px;
  border-radius: 14px;
}

.dropbtn {
  background-color: #04aa6d;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
  padding-top: 12px;
}

.dropdown1 {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border: 1px solid;
  border-radius: 6px;
  margin-left: -30%;
}

.dropdown-content1 {
  display: none;
  position: absolute;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border: 1px solid;
  border-radius: 6px;
  margin-left: -13%;
}

@media screen and (min-width: 900px) {
  .dropdown-content1 {
    margin-left: -45%;
  }
}

@media screen and (min-width: 1100px) {
  .dropdown-content1 {
    margin-left: -25%;
  }
}

.leavemessage {
  display: none;
  position: absolute;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border: 1px solid;
  border-radius: 6px;
  margin-left: -250%;
  width: 33em;
  padding: 10px;
  margin-top: 5%;
}

.titlemessage {
  display: none;
  position: absolute;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border: 1px solid;
  border-radius: 6px;
  margin-left: -124%;
  padding: 10px;
  margin-top: 5%;
}

.dropdown-content a {
  color: black;
  padding: 8px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content1 a {
  color: black;
  padding: 8px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown-content1 a:hover {
  background-color: #ddd;
}

.leavemessage a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown1:hover .dropdown-content1 {
  display: block;
}

.savebutton:hover .leavemessage {
  display: block;
}

.leavemessage:hover #toolfixed {
  z-index: -1;
}

.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}

.mcqRadioTemplateList {
  align-items: center;
}

.radioLabelQuestionTemplate {
  margin-bottom: unset;
  margin-top: 4px;
}

.checkboxLabelQuestionTemplate {
  margin-bottom: 8px;
}

input[type="checkbox"] {
  height: 23px;
  width: 23px;
}

.answerfield {
  margin-left: auto;
  margin-right: auto;
  width: 95%;
  background-color: #fff3e6;
  height: 70px;
  margin-top: 2%;
  padding: 5px;
}

.answereditor {
  margin-top: 3%;
  width: 90%;
  height: auto;
  font-size: 20px;
  text-align: left;
  word-wrap: break-word;
  overflow-wrap: break-word;
  margin-left: 1%;
  word-break: break-all;
}

.answereditor:focus-visible {
  border: 1px solid;
}

.answereditor:hover {
  border: 1px dotted;
}

.answerfield > img,
.answereditor {
  float: left;
}

.answerfield input {
  vertical-align: middle;
}

.answerfield img {
  margin-top: 3%;
}

@media screen and (max-width: 768px) {
  .answereditor {
    width: 85%;
  }
}

@-moz-document url-prefix() {
  input[type="color"] {
    border: none;
    width: 45px;
  }

  ::-moz-scrollbar {
    width: 12px;
    height: 12px;
  }
}

.dropdown-content1 {
  display: none;
  position: absolute;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border: 1px solid;
  border-radius: 6px;
  margin-left: -13%;
}

.leavemessage {
  display: none;
  position: absolute;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border: 1px solid;
  border-radius: 6px;
  margin-left: -230%;
  width: 33em;
  padding: 5px;
}

.dropdown-content a {
  color: black;
  padding: 8px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content1 a {
  color: black;
  padding: 8px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown-content1 a:hover {
  background-color: #ddd;
}

.leavemessage a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown1:hover .dropdown-content1 {
  display: block;
}

.savebutton:hover .leavemessage {
  display: block;
}

.leavemessage:hover #toolfixed {
  z-index: -1;
}

.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}

.answerfield {
  margin-left: auto;
  margin-right: auto;
  width: 95%;
  background-color: #fff3e6;
  height: 70px;
  margin-top: 2%;
}

.answereditor {
  margin-top: 3%;
  width: 90%;
  height: auto;
  font-size: 20px;
  text-align: left;
  word-wrap: break-word;
  overflow-wrap: break-word;
  padding: 4px 4px 4px 15px;
  margin-left: 1%;
  border-radius: 3px;
  border: 1px solid transparent;
  word-break: break-word;
}

.answereditor:focus-visible {
  border: 1px solid black;
  outline: none;
}

.answereditor:hover {
  border: 1px solid rgba(87, 86, 86, 0.445);
}

.answerfield > img,
.answereditor {
  float: left;
}

.answerfield input {
  vertical-align: middle;
}

.answerfield img {
  margin-top: 3%;
}

@media screen and (max-width: 768px) {
  .answereditor {
    width: 85%;
  }
}

@-moz-document url-prefix() {
  input[type="color"] {
    border: none;
    width: 45px;
  }

  ::-moz-scrollbar {
    width: 12px;
    height: 12px;
  }
}

/* select {
        background-color: white;
        border-radius: 3px;
    }

    textarea {
        resize: none;
    } */

.restoredividingline {
  height: 1px;
  border-width: 0;
  color: gray;
  background-color: white;
  margin: 2%;
  width: 97%;
}

.setanswer {
  border: 1px solid;
  margin-top: 3%;
  margin-left: auto;
  margin-right: auto;
  width: 95%;
  border-radius: 6px;
  height: auto;
  padding: 10px;
}

.setanswertext {
  font-size: 17px;
  font-weight: bold;
}

.answertypetext {
  float: left;
  width: 50%;
  text-align: right;
  font-size: 17px;
  font-weight: bold;
}

.invitemember {
  border: 2px solid;
  border-radius: 6px;
  margin-top: 6%;
  height: auto;
  padding: 10px;
}

[contenteditable][placeholder]:empty:before {
  content: attr(placeholder);
  color: gray;
  background-color: transparent;
  text-align: left;
}

.containermainpage {
  margin-top: 150px;
}

.answertypetext select {
  margin-right: 5px;
}

.dropdowntheme1 {
  width: auto;
  float: left;
  padding: 2px;
  padding-left: 3px;
  padding-right: 3px;
  background-color: white;
  font-weight: bold;
  margin-left: 2%;
  cursor: pointer;
  font-size: 13px;
}

.themedropdown {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  width: 21%;
  border: 1px solid;
  z-index: 1;
  border-radius: 6px;
  margin-top: 2px;
  cursor: pointer;
}

.dropdowntheme {
  width: 20px;
  height: 20px;
  border: 1px solid;
  display: initial;
  margin-left: 1px;
  cursor: pointer;
}

.dropdowntheme:hover .themedropdown {
  display: block;
  cursor: pointer;
}

.dropdowntheme1:hover .themedropdown {
  display: block;
  cursor: pointer;
}

.themes {
  display: inline-block;
  height: 20px;
  width: 20px;
  border: 1px solid;
  margin: 5px;
  cursor: pointer;
}

div.content {
  margin-left: 200px;
  padding: 1px 16px;
  height: 1000px;
}

.line {
  height: 2px;
  width: 90%;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
}

.dropdown {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 0px 10px;
  grid-auto-rows: minmax(0px, auto);
  margin-left: 3%;
  width: 96%;
  padding: 1%;
  margin-top: 2%;
  border-radius: 6px;
  scrollbar-width: thin;
}

.questiondiv {
  grid-column: 1 / 6;
  grid-row: 1;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  color: white;
  padding: 5px;
  border-radius: 6px;
}

.questiondiv .drag-drop {
  height: 20px;
  width: auto;
  filter: invert(1);
  opacity: 0;
  transition: all 0.2s;
  cursor: move;
}

.questiondiv:hover .drag-drop {
  opacity: 0.6;
}

.two {
  grid-column: 6 / 7;
  grid-row: 1 / 3;
}

#dropdown {
  height: 490px;
  overflow: hidden auto;
  display: block;
  counter-reset: questionnumber;
}

#dropdown::-webkit-scrollbar {
  width: 7px;
  height: 6px;
}

#dropdown::-webkit-scrollbar-track {
  border-radius: 7px;
  padding: 5px;
  background-color: lightgrey;
}

#dropdown::-webkit-scrollbar-track:hover {
  background: lightgrey;
}

#dropdown::-webkit-scrollbar-thumb {
  background: rgb(75, 75, 75);
  border-radius: 7px;
}

#dropdown {
  scrollbar-width: thin;
}

.tdimagesidebar {
  width: 100%;
  height: auto;
}

#themetable {
  border-collapse: separate;
  border-spacing: 5px;
  color: white;
  font-size: 1vw;
  font-weight: bold;
  border: none;
  width: 100%;
  margin-left: 5%;
}

#themetable td + td {
  width: 45%;
  border-radius: 6px;
}

#themetable img {
  border-radius: 6px;
}

#themetable td + td + td {
  width: 35%;
  border-radius: 6px;
}

#themetable td:nth-child(1) {
  width: 12%;
}

#themetable td {
  background: transparent;
  border-radius: 0px;
  border-bottom: none;
  padding: 0px;
}

#themetable th {
  border: none;
}

.tableline {
  border-top: 2px solid white;
}

.tableline td {
  height: 1px;
  border-top: 1px solid white;
}

.themetablediv {
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: -5px;
}

.addquestionpopup::-webkit-scrollbar {
  width: 7px;
  height: 6px;
}

.addquestionpopup::-webkit-scrollbar-track {
  border-radius: 7px;
  padding: 5px;
  background-color: lightgrey;
}

.addquestionpopup::-webkit-scrollbar-track:hover {
  background: lightgrey;
}

.addquestionpopup::-webkit-scrollbar-thumb {
  background: rgb(75, 75, 75);
  border-radius: 7px;
}

.addquestionpopup {
  position: absolute;
  width: 275px;
  max-height: 395px;
  border: 1px solid;
  margin-top: -90px;
  margin-left: 107%;
  border-radius: 6px;
  background-color: white;
  padding: 10px;
  display: none;
  font-size: 14px;
  overflow-y: auto;
  z-index: 1000;
}

.addquestionpopup a {
  text-decoration: none;
  color: black;
}

.addquestionpopup p {
  float: none;
  font-size: 17px;
  font-weight: bold;
}

.addquestionpopup div {
  padding: 3px;
  margin-top: 5px;
}

.addquestionpopup div:hover,
.addquestionpopup div.active {
  cursor: pointer;
  background-color: lightgray;
  font-weight: bold;
}

.premium {
  background-color: rgb(255, 243, 230);
}

.premium:hover {
  background-color: rgb(255, 243, 230);
}

.premium img {
  float: right;
  width: 20px;
  height: auto;
}

/* .tooltip {
        position: absolute;
        background-color: transparent;
        width: 300px;
        padding: 0%;
        position: absolute;
    } */

.tooltip-inner {
  background-color: transparent;
  color: black;
}

.questiontooltip {
  width: 300px;
  height: auto;
  background-color: white;
  opacity: 1;
  color: black;
  font-size: 15px;
  text-align: left;
  border: 1px solid;
  padding: 5px;
  border-radius: 6px;
}

.questiontooltip p {
  float: none;
}

.questiontooltip .tooltipheading {
  font-size: 17px;
  font-weight: bold;
}

.tooltipheading font {
  color: red;
}

.tooltip.in {
  opacity: 1;
}

.btndiv {
  background-color: #54b8e9;
  padding: 3px 10px 3px 10px;
  color: white;
  margin-top: 10px;
  text-align: center;
  border-radius: 4px;
  width: 100px;
}

.btndivadddocument {
  background-color: #54b8e9;
  padding: 3px 10px 3px 10px;
  color: white;
  margin-top: 10px;
  text-align: center;
  border-radius: 4px;
  width: 150px;
}

.circle {
  border: 1px solid;
  border-radius: 50%;
  height: 12px;
  width: 12px;
  display: inline-block;
}

.square {
  border: 1px solid;
  height: 12px;
  width: 12px;
  display: inline-block;
}

.ratingcontainer {
  background-color: #54b8e9;
  padding: 10px;
  border-radius: 6px;
  width: 195px;
}

.ratingstyle {
  color: gold;
  font-size: 24px;
}

.ratingunstyle {
  color: black;
  font-size: 24px;
}

.essaydiv {
  border: 1px solid;
  border-radius: 6px;
  font-size: 12px;
  color: gray;
  height: 70px;
  padding: 5px;
}

.droplistdiv {
  border: 1px solid;
  border-radius: 6px;
  font-weight: bold;
  padding: 5px;
  font-size: 14px;
}

.droplistdiv div {
  float: right;
}

.div-table {
  display: table;
  width: auto;
  border-spacing: 5px;
}

.div-table-row {
  display: table-row;
  width: auto;
}

.div-table-col {
  float: left;
  display: table-column;
  width: 68px;
  text-align: center;
  font-size: 12px;
}

.rankingrow {
  margin-top: 5px;
}

.ranking {
  border: 1px solid;
  border-radius: 3px;
  width: 32px;
  display: inline-block;
  padding: 1px;
}

.ranking i {
  float: right;
}

.recordvideotextarea {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  border-radius: 6px;
  padding: 5px;
}

.timelimit {
  float: left;
  width: 340px;
  padding-left: 5%;
  font-size: 13px;
  font-weight: bold;
  margin-left: 10%;
}

.timelimit input {
  margin: 0%;
}

.imageboxcontainer {
  position: relative;
  width: 640px;
  padding-left: 0%;
  padding-right: 0%;
  height: auto;
  background: black;
  margin: auto;
  z-index: 1;
}

.imageboxcontainer image {
  opacity: 1;
  display: block;
  width: 100%;
  height: 250px;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.imageboxcontainer:hover image {
  opacity: 0.3;
}

.imageboxcontainer .middle {
  transition: 0.5s ease;
  opacity: 1;
  position: absolute;
  top: 10%;
  left: 85%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  width: 25%;
  display: block;
  z-index: 10;
}

.imageboxcontainer:hover .middle {
  opacity: 1;
}

.imageboxcontainer .image {
  opacity: 1;
  display: block;
  width: 100%;
  max-height: 315px;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.imgbtn {
  background-color: #8c7256;
  color: white;
  border-radius: 6px;
  border: none;
  height: auto;
  width: 100%;
  font-size: 13px;
  margin-top: 5%;
  font-weight: normal;
  text-align: center;
  min-height: 20px;
}

.imgbtn input {
  display: none;
}

#Remembermecheckbox {
  height: 20px;
  width: 20px;
  margin-right: -12%;
}

label {
  font-size: 17px;
  font-weight: 500;
}

.logintittle h5 {
  margin-top: 14px;
  margin-bottom: 26px;
}

.homenavbar .navbar-nav li > a {
  padding: 16px 30px 3px 30px;
}

.container-fluid > .navbar-collapse,
.container-fluid > .navbar-header,
.container > .navbar-collapse,
.container > .navbar-header {
  margin-left: -3px;
}

a.profileanchor {
  padding-top: 14px;
}

.container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.uploadimagediv {
  width: 38%;
}

.explanation {
  width: 25%;
}

::selection {
  background-color: #3297fd;
  color: white;
}

.sidebarquizhead {
  padding-left: 10px;
  height: auto;
  padding-bottom: 5px;
  word-wrap: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: red;
}

.extentionselecttable {
  border-spacing: 20px 15px;
  border-collapse: separate;
}

.extentionselecttable td:nth-child(1) {
  font-size: 17px;
}

.extentionselecttable input[type="checkbox"] {
  margin: 0;
}

.assignpointstable pre,
.answerreport pre {
  display: block;
  padding: 0px;
  margin: auto;
  line-height: 1.1;
  word-break: break-word;
  background-color: transparent;
  border: none;
  font-family: inherit;
  white-space: pre-wrap;
  font-size: 14px;
  color: inherit;
}

.securityIntegrationContainer {
  max-width: 1700px;
  margin: auto;
  background-color: #eaf1f4;
  border-radius: 5px;
}

.security-label-correct,
.security-label-incorrect,
.security-label-unanswered {
  width: fit-content;
  margin: auto;
  border: 1px solid;
  padding: 10px;
  border-radius: 6px;
  min-width: 270px;
  text-align: center;
}

.security-label-correct {
  background-color: #f8fff9;
  color: #10be4b;
}

.security-label-incorrect {
  background-color: #fff6f6;
  color: #f33939;
}

.security-label-unanswered {
  background-color: #f8f8f8;
  border-color: #aaaaaa;
  color: black;
}

.answerreport ul {
  list-style-type: none;
  padding-left: 0;
}

.reports-multiple-choice {
  height: 15px;
  width: 15px;
  border: 1px solid;
  border-radius: 50%;
  display: inline-block;
  margin-right: 15px;
}

.reports-multiple-response {
  height: 15px;
  width: 15px;
  border: 1px solid;
  display: inline-block;
  margin-right: 15px;
}

.reports-multiple-choice.correct,
.reports-multiple-response.correct {
  background-color: #0c9a3d;
  color: #0c9a3d;
}

.reports-multiple-choice.incorrect,
.reports-multiple-response.incorrect {
  background-color: #e40000;
  color: #e40000;
}

#topheader .navbar-nav li {
  display: flex;
  align-items: center;
}

.profileanchor {
  display: flex;
  align-items: center;
}

.dropdown_container .dropdown:hover {
  background-color: #21638ba9;
  cursor: pointer;
}

.image-row img {
  width: 100px;
  padding: 35px;
  border-radius: 50%;
  background-color: white;
}

.image-row {
  display: flex;
  gap: 70px;
  margin-bottom: 40px;
  align-items: center;
  justify-content: center;
}

.hide-opacity {
  opacity: 0;
}

.pagination > li > a,
.pagination > li > span {
  font-size: 20px;
}

.toggle-section {
  display: inline-flex;
  flex-direction: row;
  gap: 1.9rem;
  margin: 0 auto;
  width: 100%;
  margin-top: 2.6rem;
  align-items: flex-end;
  font-size: 20px;
  justify-content: center;
  align-items: center;
}
.toggle-section p {
  margin-bottom: 0px;
  font-weight: 500;
}

.toggle-area {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  width: 6.8rem;
  height: 2.7rem;
  border-radius: 25px;
  background-color: #e3e3e3;
}

.toggle-btn {
  cursor: pointer;
  position: relative;
  width: 30px;
  margin-left: 3px;
  height: 30px;
  margin: 7%;
  background-color: rgb(48, 131, 255);
  border-radius: 50%;
}

.toggle-area.monthly {
  justify-content: flex-end;
  align-content: flex-end;
  background-color: rgb(147 180 251);
}

.toggle-area.anually {
  justify-content: flex-start;
  align-content: flex-start;
  background-color: #e3e3e3;
  padding: 0px !important;
}

.toggle-section p {
  color: var(--light-grayish-blue);
}

/* Pricing Section */

.pricing-container {
  display: grid;
  width: 75%;
  grid-template-columns: repeat(3, 1fr);
  margin: 0 auto;
  gap: 3%;
  margin-top: 5.8rem;
}

.pricing {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  border-radius: 20px;
  -webkit-box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.19);
  box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.19);
  justify-items: center;
}

.pricing hr {
  border-bottom: 1px solid rgb(180, 180, 180);
  width: 90%;
  margin: 0px;
}

.container-2 {
  background: linear-gradient(var(--gradient));
  color: var(--white);
  margin-top: -5.8rem;
  margin-bottom: -2rem;
  border: 2px solid;
  border-color: orange;
}

.container-1,
.container-3 {
  background-color: var(--white);
}

.pricing h2 {
  font-size: 2rem;
  font-weight: 700;
  padding: 10px;
  margin-bottom: 8px;
}

.pricing span {
  width: 60%;
  text-align: center;
  margin-bottom: 18px;
}

.container-1 h2,
.container-1 li,
.container-3 h2,
.container-3 li {
  color: var(--light-grayish-blue);
}

.pricing p {
  display: flex;
  align-items: center;
  gap: 13px;
  margin: 0 auto;
  font-size: 3.6rem;
}

.dollar-sign {
  font-size: 2.6rem;
}

.container-1 p,
.container-3 p {
  color: var(--dark-grayish-blue);
}

.container-2 p {
  margin-top: -11px;
}

.pricing ul {
  list-style: none;
  margin-top: 10px;
  padding-left: 0px;
  width: 90%;
}

.pricing ul li {
  padding: 0.5rem;
  display: flex;
  height: fit-content;
  filter: invert(0%) sepia(0%) saturate(28%) hue-rotate(48deg) brightness(92%)
    contrast(106%);
  font-size: 18px;
  align-items: flex-start;
  gap: 10px;
}

.pricing ul li p {
  font-size: 18px;
  margin: 0px;
}

.pricing button {
  cursor: pointer;
  width: 70%;
  margin: 0 auto;
  font-size: 20px;
  margin-top: 1rem;
  border-radius: 20px;
  border-color: #005284;
  border-style: solid;
  border-width: 2px;
  margin-bottom: 15px;
  color: #005284;
  background-color: white;
  padding: 0.7rem;
  letter-spacing: 0.5px;
  font-weight: 600;
  text-align: center;
}

.container-1 button,
.container-3 button,
.container-2 button {
  background: linear-gradient(var(--gradient));
  background-repeat: no-repeat;
}

@media (hover: hover) {
  .container-1 button:hover,
  .container-3 button:hover {
    color: var(--purple);
    border-width: 2px;
    background-color: #005284;
    color: white;
  }

  .container-2 button:hover {
    color: var(--white);
    border-width: 2px;
    background-color: #005284;
    color: white;
  }
}

.container-faq {
  margin: 0 auto;
  padding: 4rem;
  width: 70%;
}

.Expand .Expand-item {
  border-bottom: 1px solid #e5e5e5;
}

.Expand .Expand-item button[aria-expanded="true"] {
  border-bottom: 1px solid #03b5d2;
}

.Expand button {
  position: relative;
  display: block;
  text-align: left;
  width: 100%;
  padding: 1em 0;
  color: #131313;
  font-size: 18px;
  font-weight: 600;
  border: none;
  background: none;
  outline: none;
}

.Expand button:hover,
.Expand button:focus {
  cursor: pointer;
  color: #03b5d2;
}

.Expand button:hover::after,
.Expand button:focus::after {
  cursor: pointer;
  color: #03b5d2;
  border: 1px solid #03b5d2;
}

.Expand button .Expand-title {
  padding: 1em 1.5em 1em 0;
}

.Expand button .icon {
  display: inline-block;
  position: absolute;
  top: 18px;
  right: 0;
  width: 22px;
  height: 22px;
  border: 1px solid;
  border-radius: 22px;
}

.Expand button .icon::before {
  display: block;
  position: absolute;
  content: "";
  top: 9px;
  left: 5px;
  width: 10px;
  height: 2px;
  background: currentColor;
}

.Expand button .icon::after {
  display: block;
  position: absolute;
  content: "";
  top: 5px;
  left: 9px;
  width: 2px;
  height: 10px;
  background: currentColor;
}

.Expand button[aria-expanded="true"] {
  color: #03b5d2;
}

.Expand button[aria-expanded="true"] .icon::after {
  width: 0;
}

.Expand button[aria-expanded="true"] + .Expand-content {
  opacity: 1;
  max-height: 9em;
  transition: all 200ms linear;
  will-change: opacity, max-height;
}

.Expand .Expand-content {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition:
    opacity 200ms linear,
    max-height 200ms linear;
  will-change: opacity, max-height;
}

.Expand .Expand-content p {
  font-size: 16px;
  font-weight: 300;
  margin: 2em 0;
}

/* footer */
.new_footer_area {
  background: #fbfbfd;
}

.new_footer_top {
  padding: 20px 0px 10px;
  position: relative;
  overflow-x: hidden;
}

a:hover,
a:focus,
.btn:hover,
.btn:focus,
button:hover,
button:focus {
  text-decoration: none;
  outline: none;
}

.new_footer_top .f_social_icon a {
  width: 44px;
  height: 44px;
  line-height: 43px;
  background: transparent;
  border: 1px solid #e2e2eb;
  font-size: 24px;
}

.new_footer_top .f_social_icon a:hover {
  background: #5787c5;
  border-color: #5787c5;
  color: white;
}

.new_footer_top .f_social_icon a + a {
  margin-left: 4px;
}

.new_footer_top .f-title {
  margin-bottom: 30px;
  color: #263b5e;
}

.f_600 {
  font-weight: 600;
}

.f_size_18 {
  font-size: 18px;
}
.f_list {
  display: flex;
  gap: 35px;
  justify-content: center;
}
.f_list li {
  height: fit-content;
}
.f_list li > a {
  font-size: large;
  margin-top: 0px;
  color: rgba(5, 41, 73, 0.7) !important;
  font-weight: 600;
  text-decoration: none;
}
.f_list li > a:hover {
  text-decoration: underline;
}

.new_footer_top .footer_content.about-widget .f_list li a {
  color: #6a7695;
}

.Additional_questions {
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  box-shadow: #000000;
  border: 1px solid #e3e3e3;
  padding: 10px;
  padding-top: 12px;
}

option:nth-child(even) {
  background-color: rgb(243, 243, 243);
}

#time_header_start,
#time_header_end,
div #hr,
div #min,
div #hr1,
div #min1 {
  position: absolute;
  font-family:
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    "Open Sans",
    "Helvetica Neue",
    sans-serif;
  font-size: 15px;
  color: #272727;
}

div #hr,
div #min,
div #hr1,
div #min1 {
  font-size: 14px;
}

#time_header_end {
  top: -17px;
  left: 30px;
}

#time_header_start {
  top: -17px;
  left: -210px;
}

div #hr {
  top: 6px;
  left: -189px;
}

div #min {
  top: 6px;
  left: -135px;
}

div #hr1 {
  top: 6px;
  left: 45px;
}

div #min1 {
  top: 6px;
  left: 100px;
}

#delete_btn_tracking:hover {
  filter: brightness(0) saturate(100%) invert(16%) sepia(98%) saturate(4374%)
    hue-rotate(356deg) brightness(99%) contrast(123%);
  transform: scale(1.1);
  transition: ease 0.3s;
}

.daterangepicker th.month {
  font-size: 18px;
}

.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect,
.daterangepicker select.ampmselect {
  width: 50px;
  font-size: 14px;
}

.btn-primary:hover {
  background-color: #026fc8b0;
  border: none;
}

.hero_section {
  width: 800px;
  padding: 70px 0px 0px 0px;
  position: relative;
}

#Hero_img {
  width: 95%;
  border-radius: 30px;
  position: relative;
  left: 62px;
  -webkit-box-shadow:
    5px 5px 0px 0px #289fed,
    10px 10px 0px 0px #5fb8ff,
    15px 15px 0px 0px #a1d8ff,
    20px 20px 0px 0px #cae6ff,
    25px 25px 0px 0px #e1eeff,
    5px 5px 15px 5px rgba(0, 0, 0, 0);
  box-shadow:
    5px 0px 0px #59bdff,
    10px 10px 0px 0px #6fbfff,
    15px 15px 0px 0px #aadcff,
    20px 20px 0px 0px #cae6ff,
    25px 25px 0px 0px #e1eeff,
    5px 5px 15px 5px rgba(0, 0, 0, 0);
}

.Hero_div button:hover {
  box-shadow: none;
  transition: box-shadow 0.5s ease;
  #Hero_img {
    box-shadow: none;
    transition: box-shadow 0.5s ease;
  }
}

.Hero_div {
  top: 89px;
  position: absolute;
  right: 289px;
  width: 100%;
}

.Hero_div button {
  border: none;
  color: #fff;
  font-size: 53px;
  z-index: 7;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  right: 9px;
  top: 161px;
  box-shadow:
    inset 0 -3em 3em rgb(0 0 0 / 0%),
    0 0 0 2px rgb(255 255 255 / 0%),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
}

.Hero_div button img {
  width: 100%;
  padding: 3px 0px 7px 5px;
  filter: brightness(0) saturate(100%) invert(52%) sepia(29%) saturate(5866%)
    hue-rotate(174deg) brightness(99%) contrast(108%);
}

.createQuizBtnText {
  font-weight: 500;
  padding-top: 3px;
  padding-bottom: 3px;
}

.container_slider {
  height: 70px;
  border-radius: 0% 0% 45% 45%;
  position: relative;
  bottom: -45px;
  background-color: white;
}

#Our_Pricing {
  margin-top: -100px;
  background: rgb(223, 224, 224);
  background: linear-gradient(
    180deg,
    rgba(223, 224, 224, 0.14889705882352944) 15%,
    rgba(223, 224, 224, 0.14889705882352944) 17%,
    rgba(248, 248, 248, 1) 46%,
    rgba(255, 255, 255, 0.8799894957983193) 100%
  );
  padding: 40px;
}

#home_h3 {
  text-align: center;
  margin-top: -9px;
  width: 100%;
  border-radius: 15px 15px 0px 0px;
  background-color: orange;
  margin-bottom: 0px;
  border: 2px solid orange;
  padding: 10px 10px;
  color: white;
  text-transform: uppercase;
}

.Faq_div {
  background: rgb(223, 224, 224);
  background: linear-gradient(
    180deg,
    rgba(223, 224, 224, 0.14889705882352944) 15%,
    rgba(223, 224, 224, 0.14889705882352944) 17%,
    rgba(248, 248, 248, 1) 46%,
    rgba(255, 255, 255, 0.8799894957983193) 100%
  );
}

.btn_danger {
  color: #d9534f;
  background-color: #ffffff;
  border: 1px solid #d9534f;
  font-size: 14px;
}

.btn_danger:hover {
  color: #fff;
  background-color: #d9534f;
  text-decoration: none;
  cursor: pointer;
}

.btn_primary {
  color: #337ab7;
  background-color: #ffffff;
  border: 1px solid #2e6da4;
  font-size: 14px;
  text-decoration: none;
}

.btn_primary:hover {
  color: #fff;
  background-color: #337ab7;
  cursor: pointer;
  text-decoration: none;
}

.cross_btn:hover {
  filter: brightness(0) saturate(100%) invert(32%) sepia(55%) saturate(3855%)
    hue-rotate(334deg) brightness(87%) contrast(99%);
}

input::-webkit-scrollbar {
  width: 3px;
}

input::-webkit-scrollbar-thumb {
  color: #686868;
  background-color: rgb(99, 99, 99);
}

input::-webkit-input-placeholder {
  opacity: 0.5;
}

#desc_info:hover .descriptioncontainer {
  display: block;
  transition:
    margin-right 2s,
    color 1s;
}

#desc_quiz::after {
}
