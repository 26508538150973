:root {
  --PhoneInput-color--focus: #03b2cb;
  --PhoneInputInternationalIconPhone-opacity: 0.8;
  --PhoneInputInternationalIconGlobe-opacity: 0.65;
  --PhoneInputCountrySelect-marginRight: 0.35em;
  --PhoneInputCountrySelectArrow-width: 0.3em;
  --PhoneInputCountrySelectArrow-marginLeft: var(
    --PhoneInputCountrySelect-marginRight
  );
  --PhoneInputCountrySelectArrow-borderWidth: 1px;
  --PhoneInputCountrySelectArrow-opacity: 0.45;
  --PhoneInputCountrySelectArrow-color: currentColor;
  --PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountrySelectArrow-transform: rotate(45deg);
  --PhoneInputCountryFlag-aspectRatio: 1.5;
  --PhoneInputCountryFlag-height: 1.3em !important;
  --PhoneInputCountryFlag-borderWidth: 1px;
  --PhoneInputCountryFlag-borderColor: rgba(0, 0, 0, 0.5);
  --PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountryFlag-backgroundColor--loading: rgba(0, 0, 0, 0.1);
}

.quiztitle-detailtable textarea::placeholder {
  color: #bebebe;
  font-weight: 500;
}

#phone_error {
  color: red;
  font-size: 14px;
}

.quiztitle-detailtable {
  width: 100%;
  font-weight: 500;
  border-spacing: 15px;
  text-align: left;
}

.Takequiz_container .detailcontainer .error {
  color: red;
}

.quiztitle-detailtable tbody {
  /* display: flex; */
  flex-direction: column;
}
.quiztitle-detailtable td {
  padding: 5px;
  width: 42%;
}
.quiztitle-detailtable tr {
  margin-bottom: 0px;
}
.react-international-phone-input-container {
  width: 100%;
  min-width: 518px;
}
.quiztitle-detailtable td label {
  font-size: 16px;
  font-weight: 600;
  color: #052949;
  margin-bottom: 5px;
  display: block;
}
.quiztitle-detailtable input,
.quiztitle-detailtable select {
  width: 100%;
  resize: none;
  border: 1px solid #b6b6b6 !important;
  border-radius: 8px;
  padding: 5px 10px;
  margin-bottom: -5%;
  line-height: 24px;
}
.react-international-phone-input-container
  .react-international-phone-country-selector-button {
  border: 1px solid #b6b6b6 !important;
}

.detailcontainer {
  width: 588px;
  z-index: 2;
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.5);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.restoredowndetailcontainer {
  display: flex;
  justify-content: flex-start;
  margin: auto;
  height: 100%;
}
.Takequiz_container {
  width: 60%;
  height: 80vh;
  height: 100%;
  display: flex;
}
.takequiztittle {
  text-align: center;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  margin-block: 20px;
}

.takequiztittle .first-takequiztitle {
  display: flex;
  align-items: center;
  justify-self: center;
  flex-direction: column;
}

.takequiztittle .quiztitle {
  color: #005aaa;
  margin-bottom: 20px;
  font-size: 26px;
  margin-top: 0px;
}

.takequiztittle .quiz-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  color: #0000007a;
  font-weight: 700;
  margin-block: 20px;
  padding-inline: 20px;
  margin-top: 0px;
}

.takequiztittle .titleimage {
  margin-block: 20px;
}

.takequiztittle .descriptioncontainer {
  margin-block: 20px;
  font-size: 17px;
  /* width: 60%; */
  max-width: 700px;
}
.detailcontainer h2 {
  font-size: 26px;
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 0px;
}

#quiztakerdetails {
  /* padding: 5px 30px; */
}

.quizStartPageMain {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: fit-content !important;
}

.quizStartPageMain .quiz-draft {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  box-shadow: 0px 0px 6px rgb(209 209 209);
  padding: 40px 70px;
  color: #000000c4;
  white-space: nowrap;
  border-radius: 10px;
}

.takequizstartbtn {
  font-size: 20px;
  color: white;
  border-radius: 4px;
  border: none;
  padding: 2% 0;
  background-color: #4598e1;
  border-radius: 10px;
  width: 88%;
  margin-top: 20px;
}

.takequizstartbtn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.fullscreen_notsupport_message {
  color: red;
  font-size: 16px;
  font-weight: bold;
  width: 80%;
  margin: 0 auto;
  margin-top: 20px;
}
.quiztitle-detailtable textarea {
  border: 1px solid rgba(5, 41, 73, 0.5);
  padding: 10px;
  resize: none;
  height: 114px;
  width: 100%;
  border-radius: 10px;
}
img.bottomlogoimg {
  padding: 10px 10px;
  width: 165px;
  background: #052949;
  border-radius: 20px 0px 0px 0px;
}
.bottomlogoimgdiv {
  margin-top: auto;
  width: 100%;
  position: fixed;
  bottom: 0;
  text-align: right;
  z-index: 2;
  right: 0;
}

.quizStartPageMain .main-custom-message {
  /* width: 90%; */
  padding-inline: 50px;
  text-align: center;
  font-size: 20px;
}

.quizStartPageMain .main-custom-message-outer {
  box-shadow: 0px 0px 6px rgb(209 209 209);
  padding: 20px 50px;
  border-radius: 10px;
  margin-block: 30px;
}

.quiz-end {
  color: red;
  padding: 20px 50px;
  font-size: 20px;
}
.quiztitle-detailtable .quiz-password {
  position: relative;
}

.quiztitle-detailtable .show-eye {
  position: absolute;
  right: 10px;
  cursor: pointer;
  top: 3px;
  color: grey;
}

.quizStartPageMain .instructions {
  font-size: 20px;
  position: fixed;
  z-index: 2;
  width: 100vw;
  height: 100vh;
  background-color: #0000007a;
  backdrop-filter: blur(5px);
  left: 0px;
}

.quizStartPageMain .instructions .child-instruct {
  background-color: white;
  padding: 20px 50px;
  border-radius: 10px;
  max-width: 85%;
  width: 700px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.quizStartPageMain .instructions .child-instruct h1 {
  font-size: 1.6rem;
  text-align: center;
}

.quizStartPageMain .instructions .child-instruct p {
  font-size: 17px;
}

.quizStartPageMain .instructions .child-instruct .start-quiz-btn {
  width: fit-content;
  padding: 10px 18px;
  background-color: #4598e1;
  border-radius: 10px;
  color: white;
  font-size: 20px;
  border: none;
  margin-inline: auto;
  display: block;
}

@media screen and (max-width: 1200px) {
  .quizStartPageMain {
    width: fit-content;
    margin-inline: auto;
  }

  .takequiztittle {
    margin-inline: auto;
  }

  .Takequiz_container {
    width: 95% !important;
    flex-direction: column;
  }

  .quizStartPageMain .main-custom-message-outer {
    padding: 10px;
    max-width: 768px;
  }

  .quiz-end {
    padding: 10px;
    white-space: normal !important;
  }

  .quiz-end pre {
    white-space: normal !important;
  }

  .main-custom-message pre {
    white-space: normal !important;
  }
  .bottomlogoimgdiv {
    position: relative !important;
  }

  .bottomlogoimgdiv.first {
    position: fixed !important;
  }
}

@media screen and (max-width: 768px) {
  .takequiztittle .quiz-info {
    width: 100% !important;
  }
  .detailcontainer {
    width: 95% !important;
    margin-inline: auto;
  }
  .react-international-phone-input-container {
    min-width: 0px;
  }
  .takequiztittle .quiztitle {
    width: 95%;
  }
  .restoredowndetailcontainer {
    width: 100%;
  }
  .quizStartPageMain .quiz-draft {
    width: 95%;
    padding: 20px;
    white-space: normal;
  }
  .quizStartPageMain .main-custom-message {
    padding-inline: 10px;
  }

  .quizStartPageMain .main-custom-message-outer {
    width: 90%;
  }
}

@media screen and (max-width: 425px) {
  .takequiztittle .quiztitle {
    font-size: 22px;
  }
  .takequiztittle .first-takequiztitle img {
    margin-bottom: 5px;
  }
  .detailcontainer h2 {
    font-size: 22px;
    margin-bottom: -10px;
  }
  .quizStartPageMain .quiz-draft * {
    font-size: 17px;
    font-weight: 700;
  }

  .quiz-end {
    font-size: 17px;
  }
  .quizStartPageMain .main-custom-message-outer {
    width: 90%;
  }
  .quizStartPageMain .main-custom-message {
    font-size: 18px;
  }
}
