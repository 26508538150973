.main-offline-dialogue {
  position: fixed;
  background-color: white;
  width: 100vw;
  height: 100vh;
  top: 0px;
  z-index: 100;
}

.main-offline-dialogue .dialogue {
  max-width: 600px;
  width: 90%;
  margin-inline: auto;
  margin-top: 20px;
  padding: 20px;
  border-radius: 10px;
  background-color: white;
  border: 1px solid #4598e1;
  margin-top: 10%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.main-offline-dialogue .dialogue .dialogue-head {
  text-align: center;
}

.main-offline-dialogue .dialogue .dialogue-head h1 {
  font-size: 26px;
  font-weight: 600;
  margin-block: 0px;
}

.main-offline-dialogue .dialogue .dialogue-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.main-offline-dialogue .dialogue .dialogue-body .message {
  font-size: 18px;
  margin-block: 20px;
  line-height: 25px;
  font-weight: 500;
}

.main-offline-dialogue .dialogue .dialogue-body .btn-retry-connection {
  font-size: 20px;
  padding-inline: 40px;
  padding-block: 5px;
  background-color: #4598e1;
  height: fit-content;
  border: none;
  color: white;
  cursor: pointer;
  transition: 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 130px;
  height: 34px;
}

.main-offline-dialogue .dialogue .dialogue-body .btn-retry-connection:hover {
  box-shadow: 0px 0px 10px #4598e1;
}

.main-offline-dialogue .dialogue .dialogue-body .btn-retry-connection .loading {
  animation: rotate 1s infinite;
}

.main-offline-dialogue .loading {
  animation: rotate 1s infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
