.upload-question-top-main {
  display: flex;
}
.add-doc-question .file-size-limit {
  display: inline-block;
  margin-top: 10px;
  color: #162d49;
}

.upload-question-top-main .add-doc-question {
  padding: 20px;
  margin-top: 50px;
}
